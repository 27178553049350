import Button from 'common/components/button';
import { useEffect, useState } from 'react';
import {
  FaCircleInfo,
  FaCircleQuestion,
  FaCreditCard,
  FaIdCard,
  FaShield,
  FaUserGraduate,
} from 'react-icons/fa6';
import { FiLogOut } from 'react-icons/fi';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PlanProgressCard from '../../components/plans/plan-progress-card';
import CancelApplicationModal from '../../components/shared/cancel-application-modal';
import ConfirmActionModal from '../../components/shared/confirm-action-modal';
import LogoImage from 'common/components/logo-image';
import ApplyForPlanLayout from '../../layouts/apply-for-plan';
import ModalLayout from '../../layouts/modal';
import { logout } from '../../redux/features/auth/authSlice';
import {
  clearPlansDraft,
  selectIsSubmitted,
  selectPlanID,
  selectPlansStep,
} from '../../redux/features/plans/plansSlice';
import { FaShieldAlt } from 'react-icons/fa';
import AnchorLinkTag from 'components/shared/anchor-link-tag';

const ApplyForPlan = () => {
  // DATA INITIALIZATION
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const drafIsSubmitted = useSelector(selectIsSubmitted);
  const draftPlanStep = useSelector(selectPlansStep);
  const [searchParams] = useSearchParams();
  const paramsStep = searchParams.get('step');
  const paramsIsSubmitted = searchParams.get('isSubmitted');
  const draftPlanID = useSelector(selectPlanID);

  // STATES
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(1);
  const [cancelModalState, setCancelModalState] = useState(false);
  const [isLogoutModalOpen, setLogoutModalState] = useState(false);

  // HANDLERS
  const toggleCancelModal = () => {
    setCancelModalState(!cancelModalState);
  };
  const handleLogout = () => {
    dispatch(clearPlansDraft());
    dispatch(logout());
    navigate('/login');
  };

  // SIDE EFFECTS
  useEffect(() => {
    setIsSubmitted(
      paramsIsSubmitted
        ? JSON.parse(paramsIsSubmitted)
        : Boolean(drafIsSubmitted)
    );
  }, []);
  useEffect(() => {
    setStep(+paramsStep || draftPlanStep);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <>
      <main className='w-full h-[100dvh] flex flex-col px-5 py-6 gap-6'>

        <div className='w-full flex justify-between items-center'>
          <LogoImage className='ml-6' small={true} />

          {/* Buttons */}
          <div>
            <div className='w-fit flex gap-2'>
              {/* desktop: help button */}
              <div className='mobile:hidden'>
                <AnchorLinkTag
                  className='w-full'
                  hrefPath='mailto:members@releaserent.com'
                >
                  <Button theme='secondary' title='Get Help' padding='px-8'/>
                </AnchorLinkTag>
              </div>

              {/* mobile: go to dashboard */}
              <div className='hidden mobile:flex'>
                <Button
                  onClick={() => {
                    navigate('/dashboard/plans');
                    dispatch(clearPlansDraft());
                  }}
                  theme='secondary'
                  padding='px-6'
                  title={
                    isSubmitted || step === 1
                      ? 'Home'
                      : 'Save'
                  }
                />
              </div>

              {/* mobile: cancel application */}
              <div className='hidden mobile:flex'>
                {step === 1 || isSubmitted ? null : (
                  <Button
                    onClick={toggleCancelModal}
                    theme='secondary'
                    padding='px-6'
                    title='Cancel'
                  />
                )}
              </div>
            </div>
            
            {/* Modal */}
            <ModalLayout
              toggleModal={toggleCancelModal}
              modalIsOpen={cancelModalState}
            >
              <CancelApplicationModal
                planId={draftPlanID}
                closeModal={toggleCancelModal}
              />
            </ModalLayout>
          </div>
        </div>

        {/* mobile: header */}
        {/* <div className='w-full mobile:flex gap-7 py-5 border-y border-gray-200 hidden'>
          <span className='text-[#191923] border border-black rounded-[50%] w-12 h-12 inline-flex items-center justify-center font-semibold text-2xl'>
            0{step}
          </span>
          <div className='flex flex-col w-4/5'>
            <p className='text-2xl text-[#191923] font-bold'>
              New Coverage Plan
            </p>
            <p>{headerSubTitle[step - 1] || ''}</p>
          </div>
        </div> */}

        {/* content */}
        <div className='h-full min-w-screen overflow-hidden flex gap-8 mobile:flex-col'>
          
          {/* aside */}
          <aside className='h-full mobile:h-14 w-full max-w-xs mobile:max-w-none flex flex-col relative gap-20 mobile:w-full overflow-hidden'>
            <div className='flex flex-col gap-4 relative w-full mobile:flex-row mobile:justify-between mobile:flex-wrap'>
              {/* row dash */}
              <span className='h-1 bg-gray-200 z-10 hidden mobile:inline w-full absolute top-[50%] translate-y-[-50%]' />

              {/* progress */}
              <PlanProgressCard
                zIndex='z-50'
                title='About Your Lease'
                step={1}
                isBlurred={step < 1}
                isCompleted={step > 1}
              />
              <PlanProgressCard
                zIndex='z-50'
                Icon={FaShieldAlt}
                title='Select Your Plan'
                step={2}
                isBlurred={step < 2}
                isCompleted={step > 2}
              />
              <PlanProgressCard
                zIndex='z-50'
                Icon={FaCreditCard}
                title='Confirm Payment'
                step={3}
                isBlurred={step < 3}
                isCompleted={step > 3}
              />

              {/* step number 5, presented as 4 */}
              <PlanProgressCard
                zIndex='z-50'
                Icon={FaIdCard}
                title='Activate Plan'
                isCompleted={step > 5}
                isBlurred={step < 4}
                step={4}
              />
            </div>

            {/* save and close application */}
            {/* if they do, provide an option to head over to the dashboard or save for later */}
            <div className='flex flex-col w-full px-3 mt-auto mobile:hidden'>
              <p
                onClick={() => {
                  navigate('/dashboard/plans');
                  dispatch(clearPlansDraft());
                }}
                className='cursor-pointer hover:bg-gray-100 text-[#191923] text-base font-medium py-4 border-t border-[#E5E7EB] px-4 flex items-center gap-3'
              >
                <FiLogOut className='text-lg text-[#6A6C70]' />
                {step === 1 ? 'Go to dashboard' : 'Save & Continue later'}
              </p>

              {/* provide an option to cancel an application if the step is greater than '1' and the user hasn't submitted their application yet. */}
              {step === 1 || step >= 4 ? null : (
                <p
                  onClick={toggleCancelModal}
                  className='cursor-pointer hover:bg-gray-100 border-y border-[#E5E7EB] text-[#191923] text-base font-medium py-4 px-4 flex items-center gap-3'
                >
                  <HiMiniInformationCircle className='text-xl text-[#6A6C70]' />
                  Cancel Application
                </p>
              )}
            </div>
          </aside>

          {/* main section */}
          <ApplyForPlanLayout
            stepNumber={step}
            onBackClick={() => setStep((prevState) => prevState - 1)}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            setStepNumber={setStep}
          />
        </div>
      </main>

      {/* MODAL */}
      <ModalLayout modalIsOpen={isLogoutModalOpen}>
        <ConfirmActionModal
          onConfirmClick={handleLogout}
          onCancel={() => setLogoutModalState(false)}
        />
      </ModalLayout>
    </>
  );
};

export default ApplyForPlan;
