import React from 'react';

const ClaimsInfoLayout = ({ children, title = 'Title' }) => {
  return (
    <div className='flex flex-col gap-4 w-full px-6 pb-6'>
      {/* label */}
      <div className='w-full flex items-center justify-center relative'>
        <span className='w-full absolute h-[1px] bg-[#E5E7EB] bottom-[50%] right-[50%] translate-x-[50%] translate-y-[50%] z-10' />
        <span className='w-fit h-fit px-3 py-2 inline-flex items-center justify-center bg-[#B097E2] rounded-[20px] font-semibold text-base text-[#FFFEFA] relative z-20'>
          {title}
        </span>
      </div>

      {children}
    </div>
  );
};

export default ClaimsInfoLayout;
