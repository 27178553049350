
import { Flex, Text,  } from "@chakra-ui/react"
import ClipboardButton from "components/shared/clipboard-button"
import LogoImage from "common/components/logo-image"
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

const PlanInfoCard = ({
  streetAddress1,
  streetAddress2,
  city,
  state,
  country,
  postalCode,
  name,
  startDate,
  endDate,
  planId,
  inactive = false,
}) => {
  return(
    <Flex
      className='p-8 bg-black text-white rounded-xl flex-col gap-10 w-full max-width-4xl'
    >
      {/* logo */}
      <Flex
        className='w-full gap-4 justify-between items-center'
      >
        <LogoImage dark={false} small={true} />
        {inactive ? <Text className='uppercase tracking-wide text-sm font-bold'>Not Active</Text> : <Text className='uppercase tracking-wide text-sm font-bold'>Active</Text>}
      </Flex>

      {/* show two columns if desktop */}
      <Flex className='w-full gap-8 mobile:flex-col'>
        {/* street address and name */}
        <Flex className='flex-col gap-4 w-full'>
          <Flex className='flex-col'>
            <Text className='uppercase tracking-wider text-xl'>
              {streetAddress1}, {streetAddress2}{streetAddress2 && ', '}{city}, {state}{state && ', '}{country}{postalCode && ', '}{postalCode}
            </Text>
          </Flex>
          <Text className='text-xl tracking-wide'>
            {name}
          </Text>
        </Flex>

        {/* Coverage Start/End Date and Plan ID */}
        <Flex className='flex-col gap-4 w-full'>
          
          {/* Coverage Start/End Date */}
          <Flex className='gap-2'>
            <Flex
              className='flex-col w-full'
            >
              <Text className='uppercase tracking-wide text-sm font-bold'>
                Start Date
              </Text>

              <Text className='text-lg'>
                {formatDateToHumanDate(startDate || '')}
              </Text>
            </Flex>

            <Flex
              className='flex-col w-full'
            >
              <Text className='uppercase tracking-wide text-sm font-bold'>
                End Date
              </Text>

              <Text className='text-lg'>
                {formatDateToHumanDate(endDate || '')}
              </Text>
            </Flex>
          </Flex>

          {/* Plan ID */}
          {!inactive &&
            <Flex
              className='flex-col w-full'
            >
              <Text className='uppercase tracking-wider text-sm font-bold'>
                Plan ID
              </Text>

              <Text className='text-lg'>
                <ClipboardButton text={planId || ''} />
              </Text>
            </Flex>
          }
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PlanInfoCard
