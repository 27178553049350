import ApplyClaimsButtons from '../../../layouts/appy-claims-buttons';
import CheckBox from '../../shared/checkbox';

const StartClaimSelectType = ({
  setSelectedClaimType,
  onNext,
  onBack,
  handleBlur,
  values,
  setFieldValue,
  stepNumber,
}) => {
  return (
    <div className='w-full flex flex-col justify-between gap-7 h-full'>
      {/* inputs */}
      <div className='flex flex-col gap-5 w-full'>
        {/* job plan */}
        <label className='inline-flex items-center gap-12 mobile:gap-3'>
          {/* check */}
          <CheckBox
            name='claimType'
            onBlur={handleBlur}
            onChange={() => {
              setSelectedClaimType(1);
              setFieldValue('claimType', 'newJob');
            }}
            checked={values.claimType === 'newJob'}
          />

          {/* label text */}
          <div className='cursor-pointer rounded-[61px] px-6 py-2 text-lg w-full font-medium text-center gap-3 text-[#191923] bg-[#F2F6EF] mobile:text-left mobile:text-base mobile:px-4'>
            I’m moving early to accept a new job
          </div>
        </label>

        {/* home purchase plan */}
        <label className='inline-flex items-center gap-12 mobile:gap-3'>
          {/* check */}
          <CheckBox
            name='claimType'
            onBlur={handleBlur}
            onChange={() => {
              setSelectedClaimType(2);
              setFieldValue('claimType', 'homePurchase');
            }}
            checked={values.claimType === 'homePurchase'}
          />

          {/* label text */}
          <div className='cursor-pointer rounded-[61px] px-6 py-2 text-lg w-full font-medium text-center gap-3 text-[#191923] bg-[#F2F6EF] mobile:text-left mobile:text-base mobile:px-4'>
            I’m moving early to purchase a house
          </div>
        </label>

        {/* other plans */}
        <label className='inline-flex items-center gap-12 mobile:gap-3'>
          {/* check */}
          <CheckBox
            name='claimType'
            onBlur={handleBlur}
            onChange={() => {
              setSelectedClaimType(3);
              setFieldValue('claimType', 'other');
            }}
            checked={values.claimType === 'other'}
          />

          {/* label text */}
          <div className='cursor-pointer rounded-[61px] px-6 py-2 text-lg w-full font-medium text-center gap-3 text-[#191923] bg-[#F2F6EF] mobile:text-left mobile:text-base mobile:px-4'>
            Other reason
          </div>
        </label>
      </div>

      {/* button */}
      <ApplyClaimsButtons
        moveOn={true}
        onNextClick={onNext}
        onBackClick={onBack}
        stepNumber={stepNumber}
      />
    </div>
  );
};

export default StartClaimSelectType;
