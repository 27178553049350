import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import StatusLabel from '../../shared/status-label';

const ClaimsTable = ({ claims }) => {
  // DATA INTIAILIZATION
  const navigate = useNavigate();

  return (
    <table className='flex flex-col w-full'>

      {/* body */}
      <tbody className='w-full rounded-xl border border-gray-200 divide-y'>
        {claims.map((claim, index) => (
          <tr
            key={index}
            onClick={() => navigate(`/dashboard/moves/${claim.id}`)}
            className='grid grid-cols-[1fr_auto_160px] w-full hover:bg-gray-50 px-3 py-2 cursor-pointer'
          >
            {/* plan id and submission date */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Plan ID 
              </span>
              <span className='uppercase tracking-wider text-lg'>
                {claim?.planId}
              </span>
              <span className='text-[0.9rem]'>
                Submitted on {formatDateToHumanDate(claim.submissionAt)}
              </span>
              <div className='hidden mobile:inline-flex mt-2'>
                <StatusLabel status={claim.status} size='xs' />
              </div>
            </td>

            {/* Move out date (desktop-only) */}
            <td className='w-full p-3 text-[#191923] text-base font-medium flex items-center mobile:hidden'>
              Moving on {formatDateToHumanDate(claim.moveOutDate)}
            </td>

            {/* Status (desktop-only) */}
            <td className='w-full p-6 flex items-center mobile:hidden'>
              <div className=' inline-flex justify-between w-full items-center'>
                <StatusLabel status={claim.status} size='xs' />
                <MdKeyboardArrowRight className='text-xl text-[#191923]' />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClaimsTable;
