import { IoIosArrowBack } from 'react-icons/io';
import { RiArrowDownSFill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import ClaimsDetailAction from '../../../components/claims/claims-detail-action';
import ClaimsDetailAlert from '../../../components/claims/claims-detail-alert';
import ClaimsDetailInfo from '../../../components/claims/claims-detail-info';
import DocumentPreview from '../../../components/shared/document-preview';
import EmptyData from '../../../components/shared/empty-data';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import StatusLabel from '../../../components/shared/status-label';
import DashboardMainLayout from '../../../layouts/dashboard-main';
import { useGetSingleClaimQuery } from '../../../redux/api/ciosUsersApi/ciosUsersApi';
import { claimTypeEnum, statusEnum } from '../../../utils/enums';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';

const ClaimDetails = () => {
  // HOOKS
  const { claimId } = useParams();
  const { data: singleClaim, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId);

  return (
    <DashboardMainLayout title='Move Details'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Home
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/moves'
            className='text-[#CED0CE] text-base font-normal'
          >
            Moves{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Details{' '}
          </Link>
        </div>
        {/* mobile: back button */}
        {/* <Link
          to='/dashboard/moves'
          className='text-[#191923] text-sm font-medium hidden mobile:inline-flex items-center gap-2'
        >
          <IoIosArrowBack className='text-xl text-[#9CA3AF]' />
          Back
        </Link> */}

        {/* body content */}
        {getSingleClaimLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singleClaim) ? (
          <EmptyData />
        ) : (
          <div className='w-full bg-[#F2F6EF] mobile:bg-white rounded-[10px] gap-5'>
            {/* header */}
            <div className='flex w-full justify-between items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-[10px] mobile:rounded-none'>
              Submitted Information
              <RiArrowDownSFill className='text-base text-[#191923]' />
            </div>

            {/* body content */}
            <div className='flex w-full flex-col gap-6'>
              {/* details */}
              <div className='grid w-full grid-cols-4 gap-y-10 gap-x-6 p-5 mobile:grid-cols-1 mobile:px-0 mobile:gap-y-5'>
                {/* address */}
                {singleClaim.claimType === claimTypeEnum.PURCHASED_HOME ? (
                  <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                    <p className='text-xs text-[#191923] font-medium'>
                      Address:
                    </p>

                    <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                      {singleClaim?.purchasedHome?.homeAddress?.streetAddress1}
                    </p>
                  </div>
                ) : null}

                {/* claims submitted on */}
                <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                  <p className='text-xs text-[#191923] font-medium'>
                    Claim submitted on:
                  </p>

                  <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                    {formatDateToHumanTime(singleClaim.submissionAt)}
                  </p>
                </div>

                {/* Coverage period */}
                <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                  <p className='text-xs text-[#191923] font-medium'>
                    Date range:
                  </p>

                  <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                    {formatDateToHumanTime(singleClaim.moveOutDate)}
                  </p>
                </div>

                {/* Job title */}
                {singleClaim.claimType === claimTypeEnum.NEW_JOB ? (
                  <>
                    <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                      <p className='text-xs text-[#191923] font-medium'>
                        Job title:
                      </p>

                      <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                        {singleClaim?.newJob?.jobTitle}
                      </p>
                    </div>

                    {/* Contact name */}
                    <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                      <p className='text-xs text-[#191923] font-medium'>
                        Conact name:
                      </p>

                      <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                        {singleClaim?.newJob?.contactName}
                      </p>
                    </div>

                    {/* Contact phone */}
                    <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                      <p className='text-xs text-[#191923] font-medium'>
                        Contact phone:
                      </p>

                      <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                        {singleClaim?.newJob?.contactPhone}
                      </p>
                    </div>

                    {/* Contact email */}
                    <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                      <p className='text-xs text-[#191923] font-medium'>
                        Contact email:
                      </p>

                      <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                        {singleClaim?.newJob?.contactEmail}
                      </p>
                    </div>
                  </>
                ) : null}

                {/* Type */}
                <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                  <p className='text-xs text-[#191923] font-medium'>Type:</p>

                  <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                    {singleClaim.claimType}
                  </p>
                </div>

                {/* Claim ID */}
                <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                  <p className='text-xs text-[#191923] font-medium'>
                    Claim ID:
                  </p>

                  <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                    {claimId}
                  </p>
                </div>

                {/* Date of Offer */}
                <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                  <p className='text-xs text-[#191923] font-medium'>
                    Date of Offer:
                  </p>

                  <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                    July 26, 2023
                  </p>
                </div>

                {/* Proposed start of date */}
                {!(singleClaim.claimType === claimTypeEnum.OTHER_CLAIM) ? (
                  <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                    <p className='text-xs text-[#191923] font-medium'>
                      Proposed start of date:
                    </p>

                    <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                      {!isEmpty(singleClaim?.newJob?.proposedStart)
                        ? formatDateToHumanTime(
                            singleClaim?.newJob?.proposedStart
                          )
                        : !isEmpty(singleClaim?.purchasedHome?.proposedStart)
                        ? formatDateToHumanTime(
                            singleClaim?.purchasedHome?.proposedStart
                          )
                        : ''}
                    </p>
                  </div>
                ) : null}

                {/* status */}
                <div className='flex flex-col gap-5 mobile:gap-2 mobile:border-b mobile:border-gray-300 mobile:pb-5'>
                  <p className='text-xs text-[#191923] font-medium'>Status:</p>

                  <StatusLabel status={singleClaim.status} size='sm' />
                </div>
              </div>

              {/* files */}
              <div className='flex flex-col w-full gap-[10px] px-5 pb-5 mobile:hidden'>
                <span className='text-xs text-[#191923] font-medium'>
                  Submitted Document
                </span>
                {/* uplaaded file */}
                <DocumentPreview
                  fileUrl={
                    singleClaim?.newJob?.offerPdfUrl ||
                    singleClaim?.purchasedHome?.offerPdfUrl ||
                    singleClaim?.otherClaim?.supportingDocUrl
                  }
                />
              </div>
              {/* mobile: file preview */}
              <div className='mobile:flex flex-col w-full gap-[10px] pb-5 hidden'>
                <span className='text-xs text-[#191923] font-medium'>
                  Submitted Document
                </span>

                {/* uplaaded file */}
                <DocumentPreview
                  shortenFileName={true}
                  fileUrl={
                    singleClaim?.newJob?.offerPdfUrl ||
                    singleClaim?.purchasedHome?.offerPdfUrl ||
                    singleClaim?.otherClaim?.supportingDocUrl
                  }
                />
              </div>

              {/* display further message about the claim's status */}
              {[
                statusEnum.Draft,
                statusEnum.submitted,
                statusEnum.Completed,
                statusEnum.inReview,
                statusEnum.Expired,
              ].includes(singleClaim.status) ? (
                <></>
              ) : singleClaim.status === statusEnum.Denied ? (
                <ClaimsDetailAlert
                  alertFeedback={singleClaim?.deniedFeedback || ''}
                />
              ) : singleClaim.status === statusEnum.Approved ? (
                <ClaimsDetailAction
                  approvedOn={singleClaim.submissionAt}
                  expiresOn={singleClaim.moveOutDate}
                  deductible='No Deductible'
                  claimName={singleClaim.name}
                  claimID={singleClaim.id}
                />
              ) : singleClaim.status === statusEnum.Confirmed ? (
                <ClaimsDetailInfo
                  approvedOn={singleClaim.submissionAt}
                  expiresOn={singleClaim.moveOutDate}
                  deductible='No Deductible'
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </DashboardMainLayout>
  );
};

export default ClaimDetails;
