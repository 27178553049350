import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { statusEnum } from 'utils/enums';
import Button from 'common/components/button';
import StatusLabel from 'components/shared/status-label';
import { useDispatch } from 'react-redux';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const ActivePlanReg = () => {
  // HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trackPageLoad } = useUserTracker();
  useEffect(() => {
    trackPageLoad('activatePlanLeaseAndVerificationSuccess');
  }, []);

  return (
    <div className='w-full flex flex-col gap-10'>
      {/* message */}
      <p className='text-[#191923] text-base font-normal pl-20 pr-20'>
        Our team will review the submitted documentation and will approve or
        follow-up with questions within the next 2 business days.
      </p>

      {/* button */}
      <div className='w-full flex pl-20 pr-20'>
        <Button
          linkId='Got to Dashboard'
          onClick={() => {
            dispatch(clearPlansDraft());
            navigate('/dashboard/plans');
          }}
          title='Go to Home'
        />
      </div>
    </div>
  );
};

export default ActivePlanReg;
