import AuthLabel from 'components/shared/auth-label';
import Button from 'common/components/button';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import LogoImage from 'common/components/logo-image';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik } from 'formik';
import AuthMainLayout from 'layouts/auth-main';
import { HiOutlineMail } from 'react-icons/hi';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import { toast } from 'react-toastify';
import { useResetPasswordRequestMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import * as Yup from 'yup';
import Tracker from 'components/auth/request-reset-password/tracker';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const RequestResetPassword = () => {
  // HOOKS
  const [requestResetPassword, { isLoading, isSuccess }] =
    useResetPasswordRequestMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: '',
      }}
      onSubmit={(body) => {
        trackFormSubmission(body);
        requestResetPassword(body)
          .unwrap()
          .then((data) => {
            trackFormSubmissionSuccess(body);
            toast.success(data?.success || 'Success!', {
              toastId: 'confirmEmailSuccess',
            });
          })
          .catch((e) => {
            trackFormSubmissionError(
              body,
              e?.data?.msg || 'Confirm email failed'
            );
            toast.error(e?.data?.msg || 'Something went wrong!', {
              toastId: 'confirmEmailErrorr',
            });
          });
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('invalid email address')
          .required('Email is required'),
      })}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <AuthMainLayout>
            <div className='w-[55%] flex flex-col gap-16 mobile:w-[90%] mobile:mx-auto mobile:py-[3em]'>
              {/* mobile logo */}
              <div className='hidden mobile:flex items-center'>
                <LogoImage />
              </div>

              {/* labels */}
              <div className='flex gap-3 items-center mobile:gap-0'>
                <AuthLabel title='Log in' />
                <Link linkId='Sign up' to='/signup'>
                  <AuthLabel title='Sign up' isActive={false} />
                </Link>
              </div>

              {/* Form */}
              <Form className='flex flex-col items-stretch w-full gap-9'>
                {/* caption */}
                <div className='flex flex-col w-full'>
                  <p className='text-4xl font-bold text-[#191923]'>
                    Reset Password
                  </p>
                  <p className='font-normal text-base text-[#191923]'>
                    We will send a reset lnk to your registered email address.
                  </p>
                </div>

                {/* fields container */}
                <div className='w-full flex flex-col gap-6'>
                  {/* email fields */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        Email Address:
                      </label>
                    </WrapInputLabel>
                    <Input
                      disabled={isSuccess}
                      name='email'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('email');
                        handleChange(e);
                      }}
                      value={values.email}
                      placeholderText='example@gmail.com'
                      Icon={HiOutlineMail}
                    />
                    <FormError name='email' />
                  </div>
                </div>

                {/* button */}
                <div className='flex flex-col gap-5'>
                  {isSuccess ? (
                    <Button
                      disabled={isSuccess}
                      title='Check your email for next steps'
                    />
                  ) : (
                    <Button
                      loading={isLoading}
                      linkId='resetPasswordBtn'
                      disabled={!isValid || isLoading || isSuccess}
                      type='submit'
                      title='Reset'
                    />
                  )}
                </div>
                <Tracker
                  values={values}
                  errors={errors}
                  touched={touched}
                  formName='requestResetPassword'
                />
              </Form>
            </div>
          </AuthMainLayout>
        );
      }}
    </Formik>
  );
};

export default RequestResetPassword;
