import { createSlice } from '@reduxjs/toolkit';
import { planPaymentMode } from 'utils/enums';

const initialState = {
  plansValues: {},
  plansStep: 1,
  isSubmitted: false,
  planPaymentMode: planPaymentMode.ONE_TIME,
};

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    saveDraftPlans: (state, { payload }) => ({
      ...state,
      plansStep: payload.plansStep,
      plansValues: payload.plansValues,
      isSubmitted: payload.isSubmitted,
    }),
    savePlansValues: (state, { payload }) => ({
      ...state,
      plansValues: payload,
    }),
    setIsSubmitted: (state, { payload }) => ({
      ...state,
      isSubmitted: payload,
    }),
    setPlanStep: (state, { payload: planStepPayload }) => ({
      ...state,
      plansStep: planStepPayload,
    }),
    savePlanPaymentMode: (state, { payload: paymentMode }) => ({
      ...state,
      planPaymentMode: paymentMode,
    }),
    clearPlansDraft: () => initialState,
  },
});

// ACTIONS
export const {
  saveDraftPlans,
  savePlansValues,
  clearPlansDraft,
  setPlanStep,
  setIsSubmitted,
  savePlanPaymentMode,
} = plansSlice.actions;

// SELECTORS
export const selectPlansValues = (state) => state.plans.plansValues;
export const selectPlansStep = (state) => state.plans.plansStep;
export const selectIsSubmitted = (state) => state.plans.isSubmitted;
export const selectPlanID = (state) => state.plans.plansValues?.id || '';
export const selectPaymentMode = (state) => state.plans.planPaymentMode;

// REDUCERS
export default plansSlice.reducer;
