import { BiRightArrowAlt } from 'react-icons/bi';
import Button from 'common/components/button';

const ApplyPlansButtons = ({
  onBackClick,
  onNextClick,
  handleSubmit,
  stepNumber,
  moveOn,
  loading,
  isValid,
  disableBackBtn = false,
}) => {
  return (
    <div className='flex gap-4 items-center mobile:flex-col'>
      <div className='mobile:hidden flex w-full'>
        <Button
          disabled={disableBackBtn || stepNumber === 1}
          onClick={onBackClick}
          theme='secondary'
          title='Back'
        />
      </div>
      <Button
        loading={loading}
        disabled={loading || !isValid}
        onClick={moveOn ? onNextClick : handleSubmit}
        Icon={stepNumber < 4 && BiRightArrowAlt}
        title={stepNumber > 3 ? 'Activate Plan' : 'Next'}
      />
    </div>
  );
};

export default ApplyPlansButtons;
