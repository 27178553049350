import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { useEffect, useMemo, useState } from 'react';
import { TiHome } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { POLLING_INTERVAL } from 'utils/enums';
import ClaimsTable from '../../../components/claims/claims-table';
import EmptyClaims from '../../../components/claims/empty-claims';
import Button from 'common/components/button';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import DashboardMainLayout from '../../../layouts/dashboard-main';
import TablePaginateWrapper from '../../../layouts/table-paginate-wrapper';
import { useGetAllClaimsQuery } from '../../../redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import { Flex, Text } from '@chakra-ui/react';

const Claims = () => {
  // DATA INITIALIZATION
  const pageSize = 10;

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [pollingInterval, setPollingInterval] = useState(
    () => POLLING_INTERVAL
  );

  // HOOKS
  const { data: claimsData, isLoading: isGetAllClaimsLoading } =
    useGetAllClaimsQuery(undefined, {
      pollingInterval: pollingInterval,
    });
  const [minPageLimit, maxPageLimit] = useMemo(() => {
    const minPageLimit = (currentPage - 1) * pageSize;
    const maxPageLimit = currentPage * pageSize - 1;
    return [minPageLimit, maxPageLimit];
  }, [currentPage]);

  // USE EFFECT
  useEffect(() => {
    if (isEmpty(claimsData)) {
      setPollingInterval(0);
    } else {
      setPollingInterval(POLLING_INTERVAL);
    }
  }, [claimsData]);

  return (
    <DashboardMainLayout title={'Moves'}>
      {/* Main Container */}
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE] mobile:hidden'
          >
            Home
          </Link>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE] hidden mobile:inline'
          >
            <TiHome className='text-2xl' />
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <span className='text-[#191923] text-base font-normal'>Moves </span>
        </div>

        {/* buttons */}
        <div className='flex w-full gap-4 mobile:flex-col'>
          <Link className='w-full' to='/dashboard/apply-for-claims'>
            <Button title='Start a New Move' />
          </Link>
          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button theme='secondary' title='Chat with an Expert' />
          </AnchorLinkTag>
        </div>

        {/* table */}
        {isGetAllClaimsLoading ? (
          <LoadingSpinner />
        ) : isEmpty(claimsData) ? (
          <EmptyClaims />
        ) : (
          <>
            <Flex className='flex-col gap-8 mb-8'>

              {/* plan heading and status */}
              <Flex className='flex-col gap-4 w-full pt-8'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>Your Submitted Claims</h2>
                </Flex>
                <Text>
                  Your active coverage plan gives you the flexibility to move any time
                  between your lease start and end date. For full details on your coverage plan and claims please visit the <AnchorLinkTag className='underline' hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>lease flexibility coverage terms and conditions</AnchorLinkTag> on our website.
                </Text>
              </Flex>

              <TablePaginateWrapper
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalCount={claimsData?.length}
              >
                <ClaimsTable
                  claims={claimsData?.slice(minPageLimit, maxPageLimit)}
                />
              </TablePaginateWrapper>
            </Flex>
          </>
        )}
      </div>
    </DashboardMainLayout>
  );
};

export default Claims;
