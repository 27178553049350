import LogoImage from 'common/components/logo-image';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { GoDotFill } from 'react-icons/go';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Button from 'common/components/button';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Tracker from '../../components/auth/signup/tracker';
import AuthLabel from '../../components/shared/auth-label';
import FormError from '../../components/shared/form-error';
import PasswordInput from '../../components/shared/password-input';
import AuthMainLayout from '../../layouts/auth-main';
import {
  useConfirmAccountMutation,
  useRegisterMutation,
} from '../../redux/api/ciosUsersApi/ciosUsersApi';
import { setCredentials, logout } from '../../redux/features/auth/authSlice';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from '../../utils/regex';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { clearClaimsDraft } from 'redux/features/claims/claimsSlice';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import { PhoneNumberInput } from 'common/components/phone-input';

// TODO remove the confirm-account logic in sign up

const ConfirmAccount = () => {
  console.log('Confirm account');
  // STATES
  const [hasSignupCode, setHasSignupCode] = useState(false);

  // HOOKS
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('token');
  const firstName = searchParams.get('firstName');
  const lastName = searchParams.get('lastName');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register, { isLoading: isRegisterLoading }] = useRegisterMutation();
  const [confirmAccount, { isLoading: isConfirmingAccount }] =
    useConfirmAccountMutation();
  const {
    trackIdentity,
    trackFieldPopulate,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  useEffect(() => {
    dispatch(logout());
    dispatch(clearClaimsDraft());
    dispatch(clearPlansDraft());
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phone: '',
        password: '',
        repeatPassword: '',
      }}
      onSubmit={({ phone, password, repeatPassword }) => {
        confirmAccount({
          token: userToken,
          password: password,
          phone: phone,
        })
          .unwrap()
          .then(({ data }) => {
            toast.info(data?.message || 'Success');
            console.log('confirm account data: ', data);
            dispatch(
              setCredentials({
                isLoggedIn: true,
                token: data?.token,
                user: data?.user,
              })
            );
            navigate('/account-created-success');
          })
          .catch((error) => {
            toast.error(error?.data?.msg || 'Confirm Account Failed');
          });
      }}
      validationSchema={Yup.object({
        phone: Yup.string()
          .required('Phone number is required')
          .test('is-valid-phone', 'Phone number is invalid', (value) => {
            return isValidPhoneNumber(value || '');
          }),
        password: Yup.string()
          .required('Required')
          .min(8, 'Must be at least 8 characters long')
          .matches(NOT_STRICT_DIGIT_REGEX, 'Must contain a number')
          .matches(LOWERCASECASE_REGEX, 'Must contain a lowercase')
          .matches(UPPERCASE_REGEX, 'Must contain an uppercase'),
        repeatPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('password')], 'Passwords must match'),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        setFieldTouched,
        setFieldValue,
      }) => {
        return (
          <AuthMainLayout>
            <div className='w-[55%] flex flex-col gap-16 mobile:w-[90%] mobile:mx-auto mobile:py-[3em]'>
              {/* mobile logo */}
              <div className='hidden mobile:flex items-center justify-center'>
                <LogoImage />
              </div>

              {/* Welcome header */}
              <div className='flex flex-col'>
                <div className='text-2xl font-bold text-[#191923]'>Welcome</div>
                {/* caption */}
                <div className='text-4xl font-bold text-[#191923]'>
                  {firstName} {lastName}
                </div>
                <div className='text-xl pt-5'>
                  Please complete your account creation process by providing us
                  with your phone number and create a password.
                </div>
              </div>

              {/* Form */}
              <Form className='flex flex-col items-stretch w-full gap-9'>
                {/* fields container */}
                <div className='w-full flex flex-col gap-6'>
                  {/* phone number */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        Phone Number:
                      </label>
                    </WrapInputLabel>
                    <PhoneNumberInput
                      name='phone'
                      value={values.phone}
                      onChange={(e) => {
                        setFieldValue('phone', e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        trackFieldPopulate('phone');
                      }}
                    />
                    <FormError name='phone' />
                  </div>

                  {/* password */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        New Password:
                      </label>
                    </WrapInputLabel>
                    <PasswordInput
                      name='password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('password');
                        handleChange(e);
                      }}
                      value={values.password}
                      placeholderText='New password'
                    />
                    <FormError name='password' />

                    {/* requirements for the password fields */}
                    <ul className='flex flex-col gap-1 text-xs font-semibold text-[#191923]'>
                      <li>Password requirements:</li>

                      {/* check if password is up to 8 chars */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.length >= 8 && 'text-green-500'
                        }`}
                      >
                        {values.password.length >= 8 ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must be atleast 8 characters
                      </li>

                      {/* check if password contains atleast one uppercase letter */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.match(UPPERCASE_REGEX) &&
                          'text-green-500'
                        }`}
                      >
                        {values.password.match(UPPERCASE_REGEX) ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must contain an UPPERCASE letter
                      </li>

                      {/* check if password contains atleast one lowercase letter */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.match(LOWERCASECASE_REGEX) &&
                          'text-green-500'
                        }`}
                      >
                        {values.password.match(LOWERCASECASE_REGEX) ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must contain a lowercase letter
                      </li>

                      {/* check if password contains atleast one number */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.match(NOT_STRICT_DIGIT_REGEX) &&
                          'text-green-500'
                        }`}
                      >
                        {values.password.match(NOT_STRICT_DIGIT_REGEX) ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must contain a number
                      </li>
                    </ul>
                  </div>

                  {/*Re-enter password */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        Confirm Password:
                      </label>
                    </WrapInputLabel>
                    <PasswordInput
                      name='repeatPassword'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('repeatPassword');
                        handleChange(e);
                      }}
                      value={values.repeatPassword}
                      placeholderText='Re-enter new password'
                    />
                    <FormError name='repeatPassword' />
                  </div>
                </div>

                {/* button */}
                <Button
                  disabled={
                    !isValid || isRegisterLoading || isConfirmingAccount
                  }
                  loading={isRegisterLoading || isConfirmingAccount}
                  type='submit'
                  title='Complete Signup'
                />
                <Tracker
                  values={values}
                  errors={errors}
                  touched={touched}
                  formName='confirmAccount'
                />
              </Form>
            </div>
          </AuthMainLayout>
        );
      }}
    </Formik>
  );
};

export default ConfirmAccount;
