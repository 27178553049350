import { useSearchParams } from 'react-router-dom';
import { AddressAutofill } from '@mapbox/search-js-react';
import FileUploadInput from 'components/shared/file-upload-input';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { IoLogoUsd } from 'react-icons/io';
import { PiWarningCircleFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { uploadFileToS3 } from 'utils/uploadS3';
import * as Yup from 'yup';
import ApplyPlansButtons from '../../../layouts/apply-plans-buttons';
import {
  useCreatePlanMutation,
  useUploadRequestUrlMutation,
  useUpdateSinglePlanMutation,
} from '../../../redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import FormError from '../../shared/form-error';
import Input from '../../shared/input';
import { extractErrorMsg } from 'utils/errors';

const PlanStepOne = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const isEdit = searchParams.get('isEdit');

  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  // check for planId
  // HANDLERS
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  // HOOKS
  const savedPlansValue = useSelector(selectPlansValues);
  const dispatch = useDispatch();
  const draftedPlans = useSelector(selectPlansValues);
  const [
    // eslint-disable-next-line no-unused-vars
    createAPlan,
    { isLoading: createPlanLoading, isSuccess: isCreatePlanSuccess },
  ] = useCreatePlanMutation();
  const [
    // eslint-disable-next-line no-unused-vars
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  // eslint-disable-next-line no-unused-vars
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // SIDE EFFECTS
  useEffect(() => {
    if (isCreatePlanSuccess || isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isCreatePlanSuccess, isUpdatePlanSuccess]);

  const fieldsDisabled = !isEmpty(savedPlansValue) && !isEdit;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        currency: 'USD',
        monthlyRent: savedPlansValue?.lease?.monthlyRent || '',
        landlordName: savedPlansValue?.lease?.landlordName || '',
        lease: {
          address: {
            streetAddress1:
              savedPlansValue?.lease?.address?.streetAddress1 || '',
            streetAddress2:
              savedPlansValue?.lease?.address?.streetAddress2 || '',
            country: savedPlansValue?.lease?.address?.country || '',
            city: savedPlansValue?.lease?.address?.city || '',
            state: savedPlansValue?.lease?.address?.state || '',
            postalCode: savedPlansValue?.lease?.address?.postalCode || '',
          },
          startDate: savedPlansValue?.lease?.startDate?.split('T')[0] || '',
          endDate: savedPlansValue?.lease?.endDate?.split('T')[0] || '',
          document: savedPlansValue?.lease?.document || '',
        },
      }}
      validationSchema={Yup.object().shape({
        currency: Yup.string(),
        monthlyRent: Yup.number()
          .required('Required')
          .typeError('Must be a number')
          .positive('Must be a positive value'),
        landlordName: Yup.string().required('Required'),
        lease: Yup.object().shape({
          address: Yup.object().shape({
            streetAddress1: Yup.string().required('Required'),
            streetAddress2: Yup.string(),
            country: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            postalCode: Yup.string().required('Required'),
          }),
          startDate: Yup.date().required('Required'),
          endDate: Yup.date()
            .min(
              new Date(
                new Date(new Date().getTime() + 86400000).toDateString()
              ),
              `End Date can't be earlier than today`
            )
            .required('Required'),
          document: Yup.string(),
        }),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          lease: {
            startDate: values.lease.startDate,
            endDate: values.lease.endDate,
            currency: values.currency,
            monthlyRent: values.monthlyRent,
            address: {
              streetAddress1: values.lease.address.streetAddress1,
              streetAddress2: values.lease.address.streetAddress2,
              province: '',
              postalCode: values.lease.address.postalCode,
              city: values.lease.address.city,
              neighborhood: '',
              state: values.lease.address.state,
              zipCode: '',
              country: values.lease.address.country,
            },
            landlordName: values.landlordName,
          },
          // owner: '',
        };

        let mutation = createAPlan;
        if (planId) {
          submissionValues.planId = planId;
          mutation = updatePlan;
        }

        if (values?.lease?.document) {
          submissionValues.lease.document = values?.lease?.document;
        }

        trackFormSubmission(submissionValues);

        mutation(submissionValues)
          .unwrap()
          .then((data) => {
            dispatch(savePlansValues(data || {}));
            onNextClick();
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        isValid,
        dirty: isDirty,
        handleSubmit,
        setFieldTouched,
      }) => {
        return (
          <Form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col w-full gap-10'
          >
            {/* container */}
            <div className='flex flex-col w-full gap-7'>
              {/* monthly rent */}
              <div className='flex flex-col gap-2'>
                <WrapInputLabel isRequired={true}>
                  <label className='text-[#191923] text-lg font-medium'>
                    {`Monthly rent (USD)`}
                  </label>
                </WrapInputLabel>

                <Input
                  disabled={fieldsDisabled}
                  Icon={IoLogoUsd}
                  placeholderText='0.00'
                  value={values.monthlyRent}
                  onChange={(event) => {
                    handleChange(event);
                    setFieldTouched('monthlyRent');
                  }}
                  onBlur={handleBlur}
                  name='monthlyRent'
                />
                <FormError name='monthlyRent' />
              </div>

              {/* Name of your landlord or property management company */}
              <div className='flex flex-col gap-2'>
                <WrapInputLabel isRequired={true}>
                  <label className='text-[#191923] text-lg font-medium'>
                    Name of your landlord or property management company
                  </label>
                </WrapInputLabel>

                <Input
                  disabled={fieldsDisabled}
                  placeholderText='Enter Name'
                  value={values.landlordName}
                  onChange={(event) => {
                    handleChange(event);
                    setFieldTouched('landlordName');
                  }}
                  onBlur={handleBlur}
                  name='landlordName'
                />
                <FormError name='landlordName' />
              </div>

              {/* Street Address 1 */}
              <div className='flex flex-col gap-2'>
                <WrapInputLabel isRequired={true}>
                  <label className='text-[#191923] text-lg font-medium'>
                    Street Address 1
                  </label>
                </WrapInputLabel>
                <AddressAutofill
                  browserAutofillEnabled={false}
                  accessToken={`${process.env.REACT_APP_MAPBOX_TOKEN}`}
                >
                  <Input
                    disabled={fieldsDisabled}
                    autoComplete='street-address'
                    placeholderText='Start typing to search for an address'
                    value={values.lease.address.streetAddress1}
                    onChange={({ target: { value } }) => {
                      setFieldValue('lease.address.streetAddress1', value);
                    }}
                    onBlur={handleBlur}
                    name='lease.address.streetAddress1'
                  />
                </AddressAutofill>
                <FormError name='lease.address.streetAddress1' />
              </div>

              {/* Street Address 2 */}
              <div className='flex flex-col gap-2'>
                <WrapInputLabel>
                  <label className='text-[#191923] text-lg font-medium'>
                    Street Address 2
                  </label>
                </WrapInputLabel>
                <Input
                  disabled={fieldsDisabled}
                  autoComplete='address-line2'
                  placeholderText='Your Address'
                  value={values.lease.address.streetAddress2}
                  onChange={({ target: { value } }) => {
                    setFieldValue('lease.address.streetAddress2', value);
                  }}
                  onBlur={handleBlur}
                  name='lease.address.streetAddress2'
                />
                <FormError name='lease.address.streetAddress2' />
              </div>

              {/* city and state */}
              <div className='flex gap-3 items-start w-full pt-2 mobile:flex-col mobile:gap-7'>
                {/* City */}
                <div className='w-full flex flex-col gap-2'>
                  <WrapInputLabel isRequired={true}>
                    <label className='text-[#191923] text-lg font-medium'>
                      City
                    </label>
                  </WrapInputLabel>
                  <Input
                    disabled={fieldsDisabled}
                    autoComplete='address-level2'
                    placeholderText='Your City'
                    value={values.lease.address.city}
                    onChange={({ target: { value } }) => {
                      setFieldValue('lease.address.city', value);
                    }}
                    onBlur={handleBlur}
                    name='lease.address.city'
                  />
                  <FormError name='lease.address.city' />
                </div>
                
                {/* State */}
                <div className='w-full flex flex-col gap-2'>
                  <WrapInputLabel isRequired={true}>
                    <label className='text-[#191923] text-lg font-medium'>
                      State
                    </label>
                  </WrapInputLabel>
                  <Input
                    disabled={fieldsDisabled}
                    autoComplete='address-level1'
                    placeholderText='Your State'
                    value={values.lease.address.state}
                    onChange={({ target: { value } }) => {
                      setFieldValue('lease.address.state', value);
                    }}
                    onBlur={handleBlur}
                    name='lease.address.state'
                  />
                  <FormError name='lease.address.state' />
                </div>
              </div>

              {/* postal code and country */}
              <div className='flex gap-3 items-start w-full pt-2 mobile:flex-col mobile:gap-7'>

                {/* Postal Code */}
                <div className='w-full flex flex-col gap-2'>
                  <WrapInputLabel isRequired={true}>
                    <label className='text-[#191923] text-lg font-medium'>
                      Zip Code
                    </label>
                  </WrapInputLabel>
                  <Input
                    disabled={fieldsDisabled}
                    autoComplete='postal-code'
                    placeholderText='Your Postal Code'
                    value={values.lease.address.postalCode}
                    onChange={({ target: { value } }) => {
                      setFieldValue('lease.address.postalCode', value);
                    }}
                    onBlur={handleBlur}
                    name='lease.address.postalCode'
                  />
                  <FormError name='lease.address.postalCode' />
                </div>

                {/* Country */}
                <div className='w-full flex flex-col gap-2'>
                  <WrapInputLabel isRequired={true}>
                    <label className='text-[#191923] text-lg font-medium'>
                      Country
                    </label>
                  </WrapInputLabel>
                  <Input
                    disabled={fieldsDisabled}
                    autoComplete='country-name'
                    placeholderText='Your Country'
                    value={values.lease.address.country}
                    onChange={({ target: { value } }) => {
                      setFieldValue('lease.address.country', value);
                    }}
                    onBlur={handleBlur}
                    name='lease.address.country'
                  />
                  <FormError name='lease.address.country' />
                </div>
              </div>

              {/* lease start and end date */}
              <div className='flex flex-col gap-2 mobile:pt-5 mobile:mt-4 mobile:border-t mobile:border-gray-300'>
                <label className='text-[#191923] text-lg font-medium'>
                  When did your lease start and when is it ending?:
                </label>

                <div className='flex gap-3 items-start w-full pt-2 mobile:flex-col mobile:gap-7'>
                  {/* start date */}
                  <div className='flex flex-col gap-2 w-full'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-[#191923] text-lg font-medium'>
                        Start Date
                      </label>
                    </WrapInputLabel>

                    <Input
                      disabled={fieldsDisabled}
                      placeholderText='Select date'
                      type='date'
                      value={values.lease.startDate}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldTouched('lease.startDate');
                        const leaseStartDate = new Date(event.target.value);
                        const year = leaseStartDate.getFullYear();
                        const month = leaseStartDate.getMonth();
                        const day = leaseStartDate.getDate();
                        const yearAfter = new Date(year + 1, month, day);
                        setFieldValue('lease.endDate', formatDate(yearAfter));
                      }}
                      onBlur={handleBlur}
                      name='lease.startDate'
                    />
                    <FormError name='lease.startDate' />
                  </div>

                  {/* end date */}
                  <div className='flex flex-col gap-2 w-full'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-[#191923] text-lg font-medium'>
                        End Date
                      </label>
                    </WrapInputLabel>

                    <Input
                      disabled={fieldsDisabled}
                      placeholderText='Select date'
                      type='date'
                      value={values.lease.endDate}
                      onChange={(event) => {
                        setFieldTouched('lease.endDate');
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      name='lease.endDate'
                    />
                    <FormError name='lease.endDate' />
                  </div>
                </div>
              </div>

              {/* upload current lease */}
              <div className='flex flex-col gap-2 pt-5 mt-4 border-t border-gray-300'>
                <WrapInputLabel isRequired={false}>
                  <label className='text-[#191923] text-lg font-medium'>
                    Upload Lease Agreement
                  </label>
                </WrapInputLabel>
                <FileUploadInput
                  disableUpload={disableFileUpload || fieldsDisabled}
                  isUploadError={uploadStatus.isError}
                  isUploadLoading={uploadStatus.isLoading}
                  isUploadSuccess={
                    uploadStatus.isSuccess ||
                    !isEmpty(draftedPlans?.lease?.document)
                  }
                  handlePDFClear={() => {
                    setFieldValue('lease.document', '');
                  }}
                  handlePDFUpload={async (file) => {
                    let fileTrack = { fileName: file.name, size: file.size };
                    trackCustomEvent('fileUploadSelection', {
                      name: 'lease.document',
                      file: fileTrack,
                    });
                    let uploadUrlReq;
                    setUploadStatus({
                      isSuccess: false,
                      isLoading: true,
                      isError: false,
                    });

                    // Get the signing url for uploading to s3
                    try {
                      uploadUrlReq = await uploadRequestUrl({
                        fileName: file.name,
                        contentType: file.type,
                      }).unwrap();
                    } catch (e) {
                      setUploadStatus({
                        isSuccess: false,
                        isError: true,
                        isLoading: false,
                      });
                      toast.error(
                        e?.msg || e?.message || 'failed to upload document'
                      );
                      trackCustomEvent('fileUploadError', {
                        name: 'lease.document',
                        err: 'Failed to get the s3 signed url',
                        file: fileTrack,
                      });
                      return;
                    }

                    if (
                      !uploadUrlReq?.data?.uploadUrl ||
                      !uploadUrlReq?.data?.viewUrl
                    ) {
                      setUploadStatus({
                        isSuccess: false,
                        isError: true,
                        isLoading: false,
                      });
                      toast.error('failed to upload document');
                      trackCustomEvent('fileUploadError', {
                        name: 'lease.document',
                        s3Data: uploadUrlReq?.data,
                        err: 'The return payload from url request was wrong',
                        file: fileTrack,
                      });
                    }

                    // Upload the file to s3

                    try {
                      await uploadFileToS3({
                        s3Url: uploadUrlReq?.data?.uploadUrl,
                        file,
                      });

                      // Have to set the uploaded file url on the lease document
                      setUploadStatus({
                        isSuccess: true,
                        isError: false,
                        isLoading: false,
                      });
                      setDisableFileUpload(true);
                      setFieldValue(
                        'lease.document',
                        uploadUrlReq?.data?.viewUrl
                      );
                      toast.info(
                        uploadUrlReq?.status ||
                          'file has been uploaded successfully'
                      );
                      trackCustomEvent('fileUploadSuccess', {
                        name: 'lease.document',
                        s3Data: uploadUrlReq?.data,
                        file: fileTrack,
                      });
                    } catch (e) {
                      setUploadStatus({
                        isSuccess: false,
                        isError: true,
                        isLoading: false,
                      });
                      toast.error(
                        e?.msg || e?.message || 'failed to upload document'
                      );
                      trackCustomEvent('fileUploadError', {
                        name: 'lease.document',
                        s3Data: uploadUrlReq?.data,
                        err: 'Failed to upload to s3',
                        file: fileTrack,
                      });
                      return;
                    }
                  }}
                />
                <FormError name='lease.document' />

                {/* Label NOTE */}
                <div className='py-[.5rem] mt-5 mobile:mt-3 px-[.5rem] border-l-[11px] border-[#191923] rounded-md bg-[#F2F6EF] flex items-center gap-2'>
                  {/* icon */}
                  <PiWarningCircleFill className='text-3xl mobile:text-4xl' />

                  {/* text */}
                  <div className='text-sm font-normal text-[#191923]'>
                    <span className='font-semibold'>NOTE:</span> Your Lease
                    Agreement can be uploaded later.
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <ApplyPlansButtons
              loading={createPlanLoading || updatePlanLoading}
              stepNumber={stepNumber}
              isValid={fieldsDisabled || (isDirty && isValid)}
              handleSubmit={handleSubmit}
              moveOn={fieldsDisabled}
              onNextClick={onNextClick}
              onBackClick={onBackClick}
            />
            {/* <Tracker
              values={values}
              errors={errors}
              touched={touched}
              formName='stepThree'
            /> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlanStepOne;
