import { currencyFormatter } from 'utils/currency';
import { Divider, Flex, HStack, Text } from '@chakra-ui/react';
import { planPaymentMode } from 'utils/enums';
import { useSelector } from 'react-redux';
import {
  selectPaymentMode,
} from 'redux/features/plans/plansSlice';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

function OneTimeSummary({amount, complete=false}) {
  return (
    <>
      <Flex className='flex-col p-4 gap-2 rounded-lg border border-slate-300 border-solid'>
        {/* due now text */}
        <Text className='text-lg font-bold mb-2'>
          {complete ? 'Paid Upfront' : 'Due Upfront'}
        </Text>

        {/* one time payment */}
        <HStack className='w-full justify-between'>
          <Text>One-Time Payment</Text>
          <Text> {amount}</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider className='border-slate-400' />

        {/* Total */}
        <HStack className='w-full justify-between'>
          <Text fontSize='16px' fontWeight={700}>
            Total
          </Text>
          <Text fontSize='16px' fontWeight={700}>
            {amount}
          </Text>
        </HStack>
      </Flex>

      {/* Due monthly payment  information */}
      <Flex className='flex-col p-4 gap-2 rounded-lg border border-slate-300 border-solid'>
        {/* title */}
        <Text className='text-lg font-bold mb-2'>
          Due Monthly
        </Text>
        <HStack className='w-full justify-between'>
          <Text>No Payment Due</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider borderColor='gray.400' />

        {/* Total */}
        <HStack className='w-full justify-between'>
          <Text fontSize='16px' fontWeight={700}>
            Total
          </Text>
          <Text fontSize='16px' fontWeight={700}>
            $0.00
          </Text>
        </HStack>
      </Flex>
    </>
  )
}

function MonthlySummary({amount, endDate, complete=false}) {
  return (
    <>
      <Flex className='flex-col p-4 gap-2 rounded-lg border border-slate-300 border-solid'>
        {/* due now text */}
        <Text className='text-lg font-bold mb-2'>
          {complete ? 'Paid Upfront' : 'Due Upfront'}
        </Text>

        {/* one time payment */}
        <HStack className='w-full justify-between'>
          <Text>First Monthly Payment</Text>
          <Text> {amount}</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider className='border-slate-400' />

        {/* Total */}
        <HStack className='w-full justify-between'>
          <Text fontSize='16px' fontWeight={700}>
            Total
          </Text>
          <Text fontSize='16px' fontWeight={700}>
            {amount}
          </Text>
        </HStack>
      </Flex>

      {/* Due monthly payment  information */}
      <Flex className='flex-col p-4 gap-2 rounded-lg border border-slate-300 border-solid'>
        {/* title */}
        <Text className='text-lg font-bold mb-2'>
          Due Monthly <span className='font-normal'>(Until {endDate})</span>
        </Text>
        <HStack className='w-full justify-between'>
          <Text>Recurring Monthly Payment</Text>
          <Text>{amount}</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider borderColor='gray.400' />

        {/* Total */}
        <HStack className='w-full justify-between'>
          <Text fontSize='16px' fontWeight={700}>
            Total
          </Text>
          <Text fontSize='16px' fontWeight={700}>
            {amount}
          </Text>
        </HStack>
      </Flex>
    </>
  )
}

function View({plan, complete=false}) {
  const paymentMode = useSelector(selectPaymentMode);
  const fmtter = currencyFormatter(plan?.lease?.currency);
  const endDate = formatDateToHumanDate(plan?.lease?.endDate || '')
  const oneTimeAmount = fmtter.format(plan?.payment?.oneTimePayment || 0);
  const monthlyAmount = fmtter.format(plan?.payment?.monthlyPayment || 0);
  const isMonthly = plan?.payment.choice === 'monthly' || paymentMode === planPaymentMode.MONTHLY;
  return (
    isMonthly ? <MonthlySummary amount={monthlyAmount} endDate={endDate} complete={complete} /> : <OneTimeSummary amount={oneTimeAmount} complete={complete}/>
  )
}

export default View
