const WrapInputLabel = ({ children, isRequired }) => {
  return (
    <div className='w-full flex items-center gap-1'>
      {children}{' '}
      <>
        {isRequired === true ? (
          <span className='text-red-500 font-semibold text-base'>*</span>
        ) : (
          <span className='text-gray-400 text-base font-semibold'>
            {'(OPTIONAL)'}
          </span>
        )}
      </>
    </div>
  );
};

export default WrapInputLabel;
