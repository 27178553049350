import ModalLayout from 'layouts/modal';
import { useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { IoIosSend, IoMdSettings } from 'react-icons/io';
import { PiWarningCircleFill } from 'react-icons/pi';
import { RiComputerLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { logout, setUserDetails } from 'redux/features/auth/authSlice';
import { clearClaimsDraft } from 'redux/features/claims/claimsSlice';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import { extractInitials } from 'common/utils/extractInitials';
import { isEmpty } from 'common/utils/isEmpty';
import ActiveLink from '../active-link';
import Button from 'common/components/button';
import ConfirmActionModal from '../confirm-action-modal';
import HamburgerMenu from '../hamburger-menu/hamburger-menu';
import LogoImage from 'common/components/logo-image';
import { Icon } from '@chakra-ui/react';
import { FaRightFromBracket } from 'react-icons/fa6';
import AnchorLinkTag from '../anchor-link-tag';
import { FaHome } from 'react-icons/fa';

const NavBar = () => {
  // STATES
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [confirmModalState, setConfirmModalState] = useState(false);

  //   HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // HANDLERS
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY < 40) {
        setShow(false);
      } else if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearClaimsDraft());
    dispatch(clearPlansDraft());
    navigate('/login');
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, [isMenuOpen]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <header
      className={`w-full hidden mobile:flex items-center justify-center bg-transparent bg border-none mt-0 h-fit flex-col sticky top-0 z-[1000] bg-white [transition:transform_.4s_ease-in-out]'
      }`}
    >
      <nav className='bg-white flex justify-between gap-10 items-center w-full px-5 p-0 my-auto h-24 border-b-[1.5px] border-gray-300 z-[1000]'>
        {/* logo */}
        <Link className='hover:opacity-80' to='/dashboard/home'>
          <LogoImage className='ml-6' small={true} />
        </Link>

        {/* hamburger menu */}
        <HamburgerMenu isMenuOpen={isMenuOpen} onClick={toggleMenu} />
      </nav>

      {/* mobile menu */}
      <ul
        className={`text-gray2 bg-transparent flex flex-col [transition:transform_.4s_ease-in-out] w-full fixed h-full overflow-auto left-0 top-24 pb-24 ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-[-100dvw]'
        } pc:hidden`}
      >
        <div
          className={`w-full h-full bg-white p-4 py-6 text-center gap-2 text-sm font-normal flex flex-col [transition:transform.8s_ease-in-out] ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-[-100dvw]'
          }`}
        >
          {/* link buttons */}
          <div className='flex flex-col gap-4'>
            <ActiveLink
              Icon={FaHome}
              to={'/dashboard/home'}
              text='Home'
            />
            <ActiveLink 
              Icon={IoIosSend} 
              to={'/dashboard/plans'} 
              text='Plans' 
            />
            <ActiveLink
              Icon={PiWarningCircleFill}
              to={'/dashboard/moves'}
              text='Moves'
            />
            <ActiveLink
              Icon={IoMdSettings}
              to={'/dashboard/settings'}
              text='Profile Settings'
            />
            <AnchorLinkTag
              className='w-full'
              hrefPath='mailto:members@releaserent.com'
            >
              <Button
                theme='secondary'
                title='Chat with an Expert'
              />
            </AnchorLinkTag>
          </div>

          {/* second section */}
          <div className='flex flex-col w-full px-3 gap-4 mt-auto'>
            {/* profile details */}
            <div className='cursor-pointer border-y border-[#E5E7EB] text-[#191923] text-base font-medium px-3 py-4 grid grid-cols-[40px_auto] gap-4'>
              {/* profile image */}
              <span className='inline-flex bg-black h-10 w-10 rounded-[50%] items-center justify-center text-white font-bold text-sm'>
                {extractInitials(userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`)}
              </span>

              {/* name and email address */}
              <div className='flex flex-col w-full gap-1 text-left overflow-hidden'>
                <span className='text-base font-medium text-[#191923]'>
                  {userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`}
                </span>
                <span className='text-[#CED0CE] font-normal text-sm'>
                  {userDetails?.email}
                </span>
              </div>
            </div>

            {/* Logout */}
            <div
              onClick={() => {
                setConfirmModalState(true);
              }}
              className='flex items-center gap-4 transition-all rounded-[8px] px-6 py-4 hover:no-underline hover:bg-[#bbbbbb4e] cursor-pointer text-[#6A6C70]'
            >
              <Icon as={FaRightFromBracket} className='text-2xl' />
              <span className='text-base font-medium'>
                Logout
              </span>
            </div>
          </div>
        </div>
      </ul>

      {/* MODAL */}
      <ModalLayout modalIsOpen={confirmModalState}>
        <ConfirmActionModal
          onConfirmClick={handleLogout}
          onCancel={() => setConfirmModalState(false)}
        />
      </ModalLayout>
    </header>
  );
};

export default NavBar;
