import { IoIosArrowBack } from 'react-icons/io';
import { Flex, Text, Card, Divider, HStack, Skeleton, Icon, useClipboard, Heading } from '@chakra-ui/react';
import { RiArrowDownSFill } from 'react-icons/ri';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { Link, useParams } from 'react-router-dom';
import DocumentPreview from 'components/shared/document-preview';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import StatusLabel from 'components/shared/status-label';
import DashboardMainLayout from 'layouts/dashboard-main';
import { useGetSinglePlanQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import DownloadPdfButton from 'components/plans/download-button';
import PaymentSummary from 'components/plans/payment-summary';
import { FaCopy } from 'react-icons/fa6';
import ClipboardButton from 'components/shared/clipboard-button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import Button from 'common/components/button';
import PlanInfoCard from 'components/plans/plan-info-card';
import { useEffect } from 'react';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useDispatch } from 'react-redux';
import { setUserDetails } from 'redux/features/auth/authSlice';

const PlanDetails = () => {
  // HOOKS
  const { planId } = useParams();
  const dispatch = useDispatch();
  const { data: singlePlans, isLoading: getSinglePlanLoading } =
    useGetSinglePlanQuery(planId);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  console.log('Plan details', singlePlans);

  return (
    <DashboardMainLayout title={`Plan Details`}>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Home
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/plans'
            className='text-[#CED0CE] text-base font-normal'
          >
            Plans{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Details{' '}
          </Link>
        </div>
        {/* mobile: back button */}
        {/* <Link
          to='/dashboard/plans'
          className='text-[#191923] text-sm font-medium hidden mobile:inline-flex items-center gap-2'
        >
          <IoIosArrowBack className='text-xl text-[#9CA3AF]' />
          Back
        </Link> */}

        {/* buttons */}
        <div className='flex w-full gap-4 mobile:flex-col'>
          <Link className='w-full' to='/dashboard/apply-for-claims'>
            <Button title='Start a New Move' />
          </Link>
          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button theme='secondary' title='Chat with an Expert' />
          </AnchorLinkTag>
        </div>

        {/* plan card and payment summary */}
        {getSinglePlanLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singlePlans) ? (
          <EmptyData />
        ) : (
          <div className='w-full gap-5 mobile:bg-white'>
            <Flex className='w-full flex-col gap-10'>

              {/* plan card and info */}
              <Flex className='flex-col gap-6'>

                {/* plan heading and status */}
                <Flex className='flex-col gap-4 w-full pt-8'>
                  <Flex className='gap-4 items-center justify-between'>
                    <h2 className='text-2xl font-bold'>Your Plan</h2>
                    <StatusLabel status={singlePlans.status} />
                  </Flex>
                  <Text className='mb-4'>
                    Your coverage plan gives you the flexibility to move any time
                    between your lease start and end date. For full details on your coverage plan please visit the <AnchorLinkTag className='underline' hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>lease flexibility coverage terms and conditions</AnchorLinkTag> on our website.
                  </Text>
                </Flex>

                {/* plan info card */}
                <Flex className='flex-col gap-4 w-full'>
                  <PlanInfoCard
                    streetAddress1={singlePlans?.lease?.address?.streetAddress1}
                    streetAddress2={singlePlans?.lease?.address?.streetAddress2}
                    city={singlePlans?.lease?.address?.city}
                    state={singlePlans?.lease?.address?.state}
                    country={singlePlans?.lease?.address?.country}
                    postalCode={singlePlans?.lease?.address?.postalCode}
                    name={userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`}
                    startDate={singlePlans?.lease?.startDate}
                    endDate={singlePlans?.lease?.endDate}
                    planId={singlePlans?.id}
                  />
                  {singlePlans?.id && (
                    <DownloadPdfButton planId={singlePlans?.id} />
                  )}
                </Flex>
              </Flex>

              <Flex className='flex-col w-full gap-4' >
                {/* title */}
                <h2 className='text-2xl font-bold'>
                  Receipt & Payment Summary
                </h2>

                {/* payment information */}
                <PaymentSummary plan={singlePlans} complete={true}/>
              </Flex>
            </Flex>
          </div>
        )}
      </div>
    </DashboardMainLayout>
  );
};

export default PlanDetails;
