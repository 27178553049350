import React, { useCallback } from 'react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const Input = ({
  Icon,
  placeholderText = '',
  disableIcon = false,
  name = '',
  type = 'text',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
  autoComplete,
  readOnly,
}) => {
  const { trackFieldPopulate } = useUserTracker();
  const _onBlur = useCallback((event) => {
    if (onBlur) {
      onBlur(event);
    }
    // Tracking not done with date on blur events
    if (type !== 'date') {
      trackFieldPopulate(name);
    }
  }, []);
  const _onChange = useCallback((event) => {
    if (onChange) {
      onChange(event);
    }
    // if this is a date type, populate happens on change, not necessarily on blur
    if (type === 'date') {
      trackFieldPopulate(name);
    }
  }, []);
  return (
    <div className={`flex items-center justify-between w-full border-solid border border-[#CED0CE] rounded-md bg-white h-10 px-3 gap-x-3 ${disabled && 'bg-slate-100 opacity-60'}`}>
      {/* icon */}
      {disableIcon || !Icon ? (
        <></>
      ) : (
        <Icon className='text-lg text-[#6A6C70]' />
      )}

      {/* input */}
      <input
        readOnly={readOnly}
        autoComplete={autoComplete}
        placeholder={placeholderText}
        name={name}
        value={value}
        type={type}
        onChange={_onChange}
        onFocus={onFocus}
        onBlur={_onBlur}
        disabled={disabled}
        className='w-full border-none [outline:none] text-base text-[#191923] font-normal placeholder:text-[#6A6C70] placeholder:font-normal placeholder:text-base disabled:bg-inherit disabled:opacity-60'
      />
    </div>
  );
};

export default Input;
