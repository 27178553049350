import ClaimsInfoLayout from '../../../layouts/claims-info';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

const ClaimsDetailInfo = ({
  deductible = '',
  approvedOn = '',
  expiresOn = '',
}) => {
  return (
    <ClaimsInfoLayout title='Info'>
      {/* texts */}
      <div className='flex flex-col w-full gap-7'>
        {/* title */}
        <p className='text-xl font-semibold text-black'>Feedback</p>

        {/* text contents */}
        <div className='grid w-full grid-cols-4 gap-y-10 gap-x-6 mobile:grid-cols-1 mobile:gap-y-5'>
          {/*  deductable amount */}
          <div className='flex flex-col gap-3'>
            <p className='text-xs text-[#191923] font-medium'>
              DEDUCTIBLE AMOUNT
            </p>

            <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
              {deductible}
            </p>
          </div>

          {/* approved on */}
          <div className='flex flex-col gap-3'>
            <p className='text-xs text-[#191923] font-medium'>APPROVED ON</p>

            <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
              {formatDateToHumanDate(approvedOn)}
            </p>
          </div>

          {/* Expires on */}
          <div className='flex flex-col gap-3'>
            <p className='text-xs text-[#191923] font-medium'>EXPIRES ON</p>

            <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
              {formatDateToHumanDate(expiresOn)}
            </p>
          </div>
        </div>
      </div>
    </ClaimsInfoLayout>
  );
};

export default ClaimsDetailInfo;
