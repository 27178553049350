import Select from 'react-select';
import React, { useCallback } from 'react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const SelectInput = ({
  options,
  placeholder,
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
  defaultInputValue,
  defaultValue,
  isOptionDisabled,
  isOptionSelected,
}) => {
  const { trackFieldPopulate } = useUserTracker();
  const _onChange = useCallback((event) => {
    trackFieldPopulate(name);
    if (onChange) {
      onChange(event);
    }
  }, []);
  return (
    <Select
      isOptionSelected={isOptionSelected}
      isOptionDisabled={isOptionDisabled}
      name={name}
      onBlur={onBlur}
      onChange={_onChange}
      isDisabled={disabled}
      onFocus={onFocus}
      value={value}
      defaultInputValue={defaultInputValue}
      defaultValue={defaultValue}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#E1E6EF',
          primary: 'black',
        },
      })}
      classNames={{
        control: (state) =>
          state.isFocused
            ? `flex items-center justify-between w-full border-solid border border-[#CED0CE] rounded-md bg-white h-10 gap-x-3 ${disabled && 'bg-slate-100 opacity-60'}`
            : `flex items-center justify-between w-full border-solid border border-[#CED0CE] rounded-md bg-white h-10 gap-x-3 ${disabled && 'bg-slate-100 opacity-60'}`,
        indicatorSeparator: () => '!bg-transparent',
        valueContainer: () => '!px-3',
        option: () => 'cursor-pointer',
      }}
      placeholder={placeholder}
      options={options}
    />
  );
};

export default SelectInput;
