import Button from 'common/components/button';
import { useLayoutEffect, useState } from 'react';
import { FiLogOut, FiSend } from 'react-icons/fi';
import { GoInfo } from 'react-icons/go';
import { HiMiniInformationCircle, HiOutlineCheckBadge } from 'react-icons/hi2';
import { IoEyeOutline } from 'react-icons/io5';
import { PiWarningCircle } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PlanProgressCard from '../../components/plans/plan-progress-card';
import ConfirmActionModal from '../../components/shared/confirm-action-modal';
import LogoImage from 'common/components/logo-image';
import ApplyForClaimLayout from '../../layouts/apply-for-claim';
import ModalLayout from '../../layouts/modal';
import {
  clearClaimsDraft,
  selectClaimType,
  selectClaimsStep,
} from '../../redux/features/claims/claimsSlice';

const ApplyForClaim = () => {
  // DATA INITIALIZATION
  const mobileClaimThemeTexts = [
    {
      header: 'Start a New Claim',
      sub: 'Need to move out before your lease is over? Start a new claim with your active coverage plan.',
    },
    {
      header: 'Claim type',
      sub: 'Choose the claim that fits your purrpose.',
    },
    {
      header: 'Claim Details',
      sub: 'Please fill the following form for claims that are due to a job relocation or home purchase.',
    },
    {
      header: 'Confirm',
      sub: 'Verify the information you  provided before submitting your claim application.',
    },
    {
      header: 'Review Approved Claim',
      sub: 'Your Claim has been approved, verify and accept to finalize the claim process.',
    },
  ];

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const draftedClaimsStep = useSelector(selectClaimsStep);
  const draftedClaimsType = useSelector(selectClaimType);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsStep = searchParams.get('step');

  // STATES
  const [selectedClaimType, setSelectedClaimType] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(1);
  const [isCancelModalOpen, setCancelModalState] = useState(false);
  const [isApprovedClaim, setIsApprovedClaim] = useState(false);

  // HANDLERS
  const toggleCancelModal = () => {
    setCancelModalState(!isCancelModalOpen);
  };
  const handleGoToDashboard = () => {
    navigate('/dashboard/moves');
  };

  // SIDE EFFECTS
  useLayoutEffect(() => {
    setStep(paramsStep || draftedClaimsStep);

    // check if step params exist, then delete it to avoid unwanted buggy behavious
    if (paramsStep) {
      searchParams.delete('step');
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, []);
  useLayoutEffect(() => {
    setSelectedClaimType(draftedClaimsType);
  }, []);

  return (
    <>
      <main className='w-full h-[100dvh] flex flex-col px-5 py-6 gap-6'>
        <div className='w-full flex justify-between items-center'>
          <LogoImage className='ml-6' small={true} />
          
          {/* Buttons */}
          <div>
            <div className='w-fit flex gap-2'>
              {/* cancel application */}
              {isSubmitted ? null : (
                <div className='w-fit mobile:hidden'>
                  <Button
                    onClick={toggleCancelModal}
                    theme='secondary'
                    title='Cancel Claim'
                    padding='px-8'
                  />
                </div>
              )}

              {/* mobile: return to dashboard button */}
              {step === 4 && isSubmitted ? null : +step === 5 ? null : (
                <div className='hidden mobile:flex'>
                  <Button
                    onClick={handleGoToDashboard}
                    theme='secondary'
                    title={
                      isSubmitted || step === 1
                        ? 'Cancel Claim'
                        : 'Save For Later'
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* mobile: header */}
        {/* {step > 5 ? null : isSubmitted ? null : (
            <div className='w-full mobile:flex gap-7 pb-10 border-b border-gray-200 hidden'>
              <span className='text-[#191923] border border-black rounded-[50%] w-12 h-12 inline-flex items-center justify-center font-semibold text-2xl'>
                0{step}
              </span>
              <div className='flex flex-col gap-2 w-4/5'>
                <p className='text-2xl text-[#191923] font-bold'>
                  {mobileClaimThemeTexts[step - 1]?.header || ''}
                </p>
                {step === 3 && (
                  <p className='text-2xl text-[#191923] font-semibold'>
                    {selectedClaimType === 1
                      ? 'New Job'
                      : selectedClaimType === 2
                      ? 'Home Purchase'
                      : selectedClaimType === 3
                      ? 'Other Claims'
                      : null}
                  </p>
                )}
                <p className='text-[#191923] font-medium text-base'>
                  {mobileClaimThemeTexts[step - 1]?.sub || ''}
                </p>
              </div>
            </div>
          )} */}

        {/* content */}
        <div className='h-full min-w-screen overflow-hidden flex gap-8 mobile:flex-col'>

          {/* aside */}
          <aside className='h-full mobile:h-14 w-full max-w-xs mobile:max-w-none flex flex-col relative gap-20 mobile:w-full overflow-hidden'>
            <div className='flex flex-col gap-4 relative w-full mobile:flex-row mobile:justify-between mobile:flex-wrap'>
              {/* row dash */}
              <span className='h-1 bg-gray-200 z-10 hidden mobile:inline w-full absolute top-[50%] translate-y-[-50%]' />
              
              {/* progress */}
              <PlanProgressCard
                zIndex='z-50'
                Icon={FiSend}
                title='Move-Out Date'
                step={1}
                isBlurred={step < 1}
                isCompleted={step > 1}
              />
              <PlanProgressCard
                zIndex='z-50'
                Icon={PiWarningCircle}
                title='Claim Type'
                step={2}
                isBlurred={step < 2}
                isCompleted={step > 2}
              />
              <PlanProgressCard
                zIndex='z-50'
                Icon={GoInfo}
                title='Claim Details'
                step={3}
                isBlurred={step < 3}
                isCompleted={step > 3}
              />
              <PlanProgressCard
                zIndex='z-50'
                Icon={HiOutlineCheckBadge}
                title='Submit Claim'
                isCompleted={step > 4 || isSubmitted}
                isBlurred={step < 4}
                step={4}
              />
              <PlanProgressCard
                zIndex='z-50'
                Icon={IoEyeOutline}
                title='Review Claim'
                isCompleted={step > 5 || isApprovedClaim}
                step={5}
                isBlurred={step < 5}
              />
            </div>

            {/* save and close applicaiton */}
            <div className='flex flex-col w-full px-3 mt-auto mobile:hidden'>
              {/* navigate user to dashboard */}
              <p
                onClick={handleGoToDashboard}
                className='cursor-pointer hover:bg-gray-100 text-[#191923] text-base font-medium py-4 border-t border-[#E5E7EB] px-4 flex items-center gap-3'
              >
                <FiLogOut className='text-lg text-[#6A6C70]' />
                {isSubmitted || step === 1
                  ? 'Go to Home'
                  : 'Save & Continue Later'}
              </p>

              {/* cancel application */}
              {isSubmitted ? null : (
                <p
                  onClick={toggleCancelModal}
                  className='cursor-pointer hover:bg-gray-100 border-y border-[#E5E7EB] text-[#191923] text-base font-medium py-4 px-4 flex items-center gap-3'
                >
                  <HiMiniInformationCircle className='text-xl text-[#6A6C70]' />
                  Cancel Application
                </p>
              )}
            </div>
          </aside>

          {/* main section */}
          <ApplyForClaimLayout
            selectedClaimType={selectedClaimType}
            setSelectedClaimType={setSelectedClaimType}
            stepNumber={step}
            setStepNumber={setStep}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            isApprovedClaim={isApprovedClaim}
            setIsApprovedClaim={setIsApprovedClaim}
          />
        </div>
      </main>
      
      {/* Modal */}
      <ModalLayout
        toggleModal={toggleCancelModal}
        modalIsOpen={isCancelModalOpen}
      >
        <ConfirmActionModal
          onConfirmClick={() => {
            dispatch(clearClaimsDraft());
            handleGoToDashboard();
          }}
          onCancel={() => setCancelModalState(false)}
        />
      </ModalLayout>
    </>
  );
};

export default ApplyForClaim;
