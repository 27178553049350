import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { useEffect, useMemo, useState } from 'react';
import { TiHome } from 'react-icons/ti';
// import { Link } from 'react-router-dom';
import { POLLING_INTERVAL } from 'utils/enums';
import PlansTable from '../../../components/plans/plans-table';
import Button from 'common/components/button';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import DashboardMainLayout from '../../../layouts/dashboard-main';
import TablePaginateWrapper from '../../../layouts/table-paginate-wrapper';
import { useGetAllPlansQuery, useRetrieveUserDetailsQuery } from '../../../redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import useTracker from 'common/utils/user-tracker/useTracker';
import EmptyPlans from 'components/plans/empty-plans';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';
import PlanInfoCard from 'components/plans/plan-info-card';
import DownloadPdfButton from 'components/plans/download-button';

const Plans = () => {
  // DATA INITIALIZATION
  const pageSize = 100;
  const navigate = useNavigate();

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [pollingInterval, setPollingInterval] = useState(
    () => POLLING_INTERVAL
  );

  // HOOKS
  const { data: plansData, isLoading: getAllPlansLoading } =
    useGetAllPlansQuery(undefined, {
      pollingInterval: pollingInterval,
    });
  const { trackLink } = useUserTracker();
  useTracker({ pageId: 'plansList' });
  const [minPageLimit, maxPageLimit] = useMemo(() => {
    const minPageLimit = (currentPage - 1) * pageSize;
    const maxPageLimit = currentPage * pageSize - 1;
    return [minPageLimit, maxPageLimit];
  }, [currentPage]);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // USE EFFECT
  useEffect(() => {
    if (isEmpty(plansData)) {
      setPollingInterval(0);
    } else {
      setPollingInterval(POLLING_INTERVAL);
    }
  }, [plansData]);

  return (
    <DashboardMainLayout title='Plans'>
      <div className='flex flex-col w-full gap-6 mobile:h-full'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            linkId={'plansDashboardHomeBtn'}
            className='font-normal text-base text-[#CED0CE] mobile:hidden'
          >
            Home
          </Link>
          <Link
            to='/dashboard/home'
            linkId={'plansDashboardHomeBtn'}
            className='font-normal text-base text-[#CED0CE] hidden mobile:inline'
          >
            <TiHome className='text-2xl' />
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <span className='text-[#191923] text-base font-normal'>
            Plans{' '}
          </span>
        </div>

        {/* buttons */}
        <div className='flex w-full gap-4 mobile:flex-col'>
          <Link
            className='w-full'
            to='/dashboard/apply-for-plans'
            linkId='Apply For A Plan'
          >
            <Button title='Apply For A Plan' />
          </Link>
          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button
              theme='secondary'
              title='Chat with an Expert'
              onClick={() => {
                trackLink('Chat with an Expert');
              }}
            />
          </AnchorLinkTag>
        </div>
        
        {getAllPlansLoading ? (
          <LoadingSpinner />
        ) : isEmpty(plansData) ? (
          <EmptyPlans />
        ) : (
          <>
            {/* active plans */}
            <Flex className='flex-col gap-8 mb-8'>

              {/* plan heading and status */}
              <Flex className='flex-col gap-4 w-full pt-8'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>Your Active Plans</h2>
                </Flex>
                <Text>
                  Your coverage plan gives you the flexibility to move any time
                  between your lease start and end date. For full details on your coverage plan please visit the <AnchorLinkTag className='underline hover:opacity-80 transition-all' hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>lease flexibility coverage terms and conditions</AnchorLinkTag> on our website.
                </Text>
              </Flex>

              {plansData.map((plan, index) => (
                plan.status == 'active' &&
                <div>
                  {/* plan info card */}
                  <Flex className='flex-col gap-4 w-full'>
                    <div className='cursor-pointer hover:opacity-80 transition-all' onClick={() => navigate(`/dashboard/plans/${plan.id}`)} >
                      <PlanInfoCard
                        streetAddress1={plan.lease.address.streetAddress1}
                        streetAddress2={plan.lease.address.streetAddress2}
                        city={plan.lease.address.city}
                        state={plan.lease.address.state}
                        country={plan.lease.address.country}
                        postalCode={plan.lease.address.postalCode}
                        name={userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`}
                        startDate={plan.lease.startDate}
                        endDate={plan.lease.endDate}
                        planId={plan.id}
                      />
                    </div>
                    {plan.id && (
                      <DownloadPdfButton planId={plan.id} />
                    )}
                  </Flex>
                </div>
              ))}
            </Flex>
            
            {/* applications */}
            <Flex className='flex-col gap-8'>

              {/* plan heading and status */}
              <Flex className='gap-4 items-center justify-between'>
                <h2 className='text-2xl font-bold'>Your Applications</h2>
              </Flex>

              {/* table */}
              <TablePaginateWrapper
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalCount={plansData?.length}
              >
                <PlansTable plans={plansData?.slice(minPageLimit, maxPageLimit)} />
              </TablePaginateWrapper>
            </Flex>
          </>
        )}
      </div>
    </DashboardMainLayout>
  );
};

export default Plans;
