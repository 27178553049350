import LogoImage from 'common/components/logo-image';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { BsTelephone } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi';
import { GoDotFill } from 'react-icons/go';
import { HiOutlineMail } from 'react-icons/hi';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty } from 'common/utils/isEmpty';
import * as Yup from 'yup';
import Tracker from 'components/auth/signup/tracker';
import AuthLabel from 'components/shared/auth-label';
import Button from 'common/components/button';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import PasswordInput from 'components/shared/password-input';
import AuthMainLayout from 'layouts/auth-main';
import {
  useConfirmAccountMutation,
  useRegisterMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { setCredentials } from 'redux/features/auth/authSlice';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from 'utils/regex';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { PhoneNumberInput } from 'common/components/phone-input';

// TODO remove the confirm-account logic in sign up

const SignUp = () => {
  // STATES
  const [hasSignupCode, setHasSignupCode] = useState(false);

  // HOOKS
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register, { isLoading: isRegisterLoading }] = useRegisterMutation();
  const [confirmAccount, { isLoading: isConfirmingAccount }] =
    useConfirmAccountMutation();
  const {
    trackLink,
    trackIdentity,
    trackFieldPopulate,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        repeatPassword: '',
        signupCode: '',
      }}
      onSubmit={({
        firstName,
        lastName,
        email,
        phone,
        signupCode,
        password,
        repeatPassword,
      }) => {
        if (!isEmpty(userToken)) {
          confirmAccount({
            token: userToken,
            name: `${firstName} ${lastName}`,
            password: password,
            phone: phone,
          })
            .unwrap()
            .then(({ data }) => {
              toast.info(data?.message || 'Success');
              dispatch(
                setCredentials({
                  isLoggedIn: true,
                  token: data?.token,
                  user: data?.user,
                })
              );
              navigate('/account-created-success');
            })
            .catch((error) => {
              toast.error(error?.data?.msg || 'Registration Failed');
            });
        } else {
          let valueCp = {
            firstName,
            lastName,
            email,
            phone,
            signupCode,
            password,
            repeatPassword,
          };
          valueCp.password = '*'.repeat(valueCp.repeatPassword.length);
          valueCp.repeatPassword = '*'.repeat(valueCp.repeatPassword.length);
          trackFormSubmission(valueCp);
          register({
            name: `${firstName} ${lastName}`,
            firstName,
            lastName,
            signupCode,
            email,
            phone,
            password,
          })
            .unwrap()
            .then(({ data }) => {
              toast.info(data?.message || 'Success');
              trackIdentity(data?.user?.id || 'unknown-id', data?.user || {});
              trackFormSubmissionSuccess(valueCp);
              dispatch(
                setCredentials({
                  isLoggedIn: true,
                  token: data?.token,
                  user: data?.user,
                })
              );
              navigate('/confirm-email');
            })
            .catch((error) => {
              toast.error(error?.data?.msg || 'Registration Failed');
              trackFormSubmissionError(
                valueCp,
                error?.data?.msg || 'Registration Failed'
              );
            });
        }
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        phone: Yup.string()
          .required('Phone number is required')
          .test('is-valid-phone', 'Phone number is invalid', (value) => {
            return isValidPhoneNumber(value || '');
          }),
        password: Yup.string()
          .required('Required')
          .min(8, 'Must be at least 8 characters long')
          .matches(NOT_STRICT_DIGIT_REGEX, 'Must contain a number')
          .matches(LOWERCASECASE_REGEX, 'Must contain a lowercase')
          .matches(UPPERCASE_REGEX, 'Must contain an uppercase'),
        repeatPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('password')], 'Passwords must match'),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        setFieldTouched,
        setFieldValue,
      }) => {
        return (
          <AuthMainLayout>
            <div className='w-[55%] flex flex-col gap-16 mobile:w-[90%] mobile:mx-auto mobile:py-[3em]'>
              {/* mobile logo */}
              <div className='hidden mobile:flex items-center'>
                <LogoImage />
              </div>

              {/* labels */}
              <div className='flex gap-3 items-center mobile:gap-0'>
                <Link to='/login' linkId='signupLoginBtn'>
                  <AuthLabel title='Log in' isActive={false} />
                </Link>
                <AuthLabel title='Sign up' />
              </div>

              {/* Form */}
              <Form className='flex flex-col items-stretch w-full gap-9'>
                {/* caption */}
                <p className='text-4xl font-bold text-[#191923]'>
                  Create Account
                </p>

                {/* fields container */}
                <div className='w-full flex flex-col gap-6'>
                  {/*Full name */}
                  <div className='flex flex-col gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        Full Name:
                      </label>
                    </WrapInputLabel>

                    <div className='flex items-start gap-5'>
                      {/* first Name */}
                      <div className='w-full flex-col flex gap-2'>
                        <Input
                          name='firstName'
                          value={values.firstName}
                          onChange={(e) => {
                            setFieldTouched('firstName');
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          placeholderText='First name'
                          Icon={FaRegUser}
                        />
                        <FormError name='firstName' />
                      </div>

                      {/* Last Name */}
                      <div className='w-full flex-col flex gap-2'>
                        <Input
                          name='lastName'
                          value={values.lastName}
                          onChange={(e) => {
                            setFieldTouched('lastName');
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          placeholderText='Last name'
                          Icon={FaRegUser}
                        />
                        <FormError name='lastName' />
                      </div>
                    </div>
                  </div>

                  {/* email */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        Email Address:
                      </label>
                    </WrapInputLabel>
                    <Input
                      name='email'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('email');
                        handleChange(e);
                      }}
                      value={values.email}
                      placeholderText='Email address'
                      Icon={HiOutlineMail}
                    />
                    <FormError name='email' />
                  </div>

                  {/* phone number */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        Phone Number:
                      </label>
                    </WrapInputLabel>
                    <PhoneNumberInput
                      name='phone'
                      value={values.phone}
                      onChange={(e) => {
                        setFieldValue('phone', e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        trackFieldPopulate('phone');
                      }}
                    />
                    <FormError name='phone' />
                  </div>

                  {/* password */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        New Password:
                      </label>
                    </WrapInputLabel>
                    <PasswordInput
                      name='password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('password');
                        handleChange(e);
                      }}
                      value={values.password}
                      placeholderText='New password'
                    />
                    <FormError name='password' />

                    {/* requirements for the password fields */}
                    <ul className='flex flex-col gap-1 text-sm text-[#191923]'>
                      <li className='font-semibold'>Password requirements:</li>

                      {/* check if password is up to 8 chars */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.length >= 8 && 'text-green-500'
                        }`}
                      >
                        {values.password.length >= 8 ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must be at least 8 characters
                      </li>

                      {/* check if password contains atleast one uppercase letter */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.match(UPPERCASE_REGEX) &&
                          'text-green-500'
                        }`}
                      >
                        {values.password.match(UPPERCASE_REGEX) ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must contain an UPPERCASE letter
                      </li>

                      {/* check if password contains atleast one lowercase letter */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.match(LOWERCASECASE_REGEX) &&
                          'text-green-500'
                        }`}
                      >
                        {values.password.match(LOWERCASECASE_REGEX) ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must contain a lowercase letter
                      </li>

                      {/* check if password contains atleast one number */}
                      <li
                        className={`inline-flex items-center gap-2 ${
                          values.password.match(NOT_STRICT_DIGIT_REGEX) &&
                          'text-green-500'
                        }`}
                      >
                        {values.password.match(NOT_STRICT_DIGIT_REGEX) ? (
                          <GiCheckMark />
                        ) : (
                          <GoDotFill />
                        )}
                        Must contain a number
                      </li>
                    </ul>
                  </div>

                  {/*Re-enter password */}
                  <div className='w-full flex-col flex gap-[10px]'>
                    <WrapInputLabel isRequired={true}>
                      <label className='text-lg font-medium text-[#191923]'>
                        Confirm Password:
                      </label>
                    </WrapInputLabel>
                    <PasswordInput
                      name='repeatPassword'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('repeatPassword');
                        handleChange(e);
                      }}
                      value={values.repeatPassword}
                      placeholderText='Re-enter new password'
                    />
                    <FormError name='repeatPassword' />
                  </div>

                  {/* signup code */}
                  {hasSignupCode ? (
                    <div className='flex flex-col w-full gap-7'>
                      <span className='h-[1px] w-full bg-[#6A6C70]' />

                      <Input
                        name='signupCode'
                        value={values.signupCode}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldTouched('signupCode');
                          handleChange(e);
                        }}
                        disableIcon={true}
                        placeholderText='Signup code'
                        Icon={BsTelephone}
                      />
                    </div>
                  ) : (
                    <p
                      onClick={() => {
                        setHasSignupCode(true);
                        trackLink('Have a signup code?');
                      }}
                      className='text-base text-[#191923] font-bold underline hover:opacity-80 cursor-pointer'
                    >
                      Have a signup code?
                    </p>
                  )}
                </div>

                {/* button */}
                <Button
                  disabled={
                    !isValid || isRegisterLoading || isConfirmingAccount
                  }
                  loading={isRegisterLoading || isConfirmingAccount}
                  type='submit'
                  title='Sign up'
                />
                <Tracker
                  values={values}
                  errors={errors}
                  touched={touched}
                  formName='signup'
                />
              </Form>
            </div>
          </AuthMainLayout>
        );
      }}
    </Formik>
  );
};

export default SignUp;
