import {
  Card,
  Divider,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import Button from 'common/components/button';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSinglePlanQuery, useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  savePlanPaymentMode,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { currency, planPaymentMode } from 'utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { FaStar } from 'react-icons/fa6';
import ClipboardButton from 'components/shared/clipboard-button';
import PlanInfoCard from '../plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import StatusLabel from 'components/shared/status-label';

const PlanStepTwo = ({ onNextClick, isGettingPlan }) => {
  // HOOKS
  const { trackPageLoad, trackCustomEvent } = useUserTracker();
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
  useRetrieveUserDetailsQuery();

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);
  // For some reason I can't use the tracker element, causes weird page loading issues
  useEffect(() => {
    trackPageLoad('planStepTwo');
  }, []);

  // DATA INITIALIZATION
  const dispatch = useDispatch();
  const plansValue = useSelector(selectPlansValues);
  const address = plansValue?.lease?.address || {};
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });

  let plan = singlePlan || plansValue;
  const planId = plansValue?.id || singlePlan?.id || 'unknown';
  const fmtter = currencyFormatter(plan?.lease?.currency);
  const oneTimePayment = fmtter.format(plan?.payment?.oneTimePayment);
  const oneTimeMonthlyAmount = fmtter.format(plan?.payment?.oneTimeMonthlyAmt);
  const oneTimeSaveAmount = fmtter.format(
    plan?.payment?.monthlyPayment - plan?.payment?.oneTimeMonthlyAmt
  );
  const monthlyAmount = fmtter.format(plan?.payment?.monthlyPayment);

  return (
    <Flex w='100%' flexDir='column' gap={10}>
      {isGettingSinglePlan || isGettingPlan ? (
        <Flex flexDir='column' gap='20px'>
          <Skeleton rounded='10px' h='10dvh' w='100%' />
          <Flex w='100%' gap='20px'>
            <Skeleton rounded='10px' h='40dvh' w='100%' />
            <Skeleton rounded='10px' h='40dvh' w='100%' />
          </Flex>
          <Skeleton rounded='10px' h='40dvh' w='100%' />
          <Skeleton rounded='10px' h='40dvh' w='100%' />
        </Flex>
      ) : isEmpty(singlePlan) ? (
        <Flex
          border='1px solid'
          borderColor='gray.300'
          rounded='12px'
          w='100%'
          alignItems='center'
          justifyContent='center'
          h='60dvh'
        >
          <Text fontWeight={600} fontSize='20px'>
            No plan found!
          </Text>
        </Flex>
      ) : (
        <>
          {/* container */}
          <Flex w='100%' flexDir='column' gap={10}>
            {/* payment cards */}
            <Flex className='mobile:flex-col' gap='20px'>
              {/* one time card */}
              <Card
                border='1px solid #E1E6EF'
                rounded='10px'
                className='relative pt-10 pb-10 pl-5 pr-5 w-[50%] mobile:w-full'
                gap={10}
              >
                {/* Deal Highlight */}
                {/* <div className='absolute top-0 left-0 right-0 py-4 px-8 flex gap-4 bg-[#191923] text-white text-xl rounded-t-[10px] justify-between'>
                  <div className='font-bold flex gap-2 items-center'>
                    <Icon as={FaStar} />
                    BEST VALUE
                  </div>
                  <div className='font-semibold'>{`SAVE ${oneTimeSaveAmount}`}</div>
                </div> */}

                {/* cost section */}
                <VStack
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  spacing={5}
                >
                  {/* tags */}
                  <Tag
                    w='fit-content'
                    py='4px'
                    px='16px'
                    rounded='14px'
                    bg='#E1E6EF'
                    textColor='#191923'
                  >
                    ONE-TIME PAYMENT
                  </Tag>

                  {/* cost price */}
                  <HStack alignItems='flex-end'>
                    <Text
                      lineHeight='42px'
                      textColor='#111827'
                      fontWeight={800}
                      fontSize='40px'
                    >
                      {oneTimeMonthlyAmount}
                    </Text>
                    <Text fontSize='24px' fontWeight={500} textColor='#191923'>
                      /mo
                    </Text>
                  </HStack>
                </VStack>

                {/* CTA section */}
                <Button
                  onClick={() => {
                    trackCustomEvent('planSelected', {
                      paymentType: 'one-time',
                      planId,
                    });
                    dispatch(savePlanPaymentMode(planPaymentMode.ONE_TIME));
                    onNextClick();
                  }}
                  title='Purchase Plan'
                />

                {/* additional information section */}
                <Flex flexDir='column' gap={3} mt={3}>
                  {/* one time payment */}
                  <HStack w='100%' justifyContent='space-between'>
                    <Text fontSize='16px'>One-Time Payment</Text>
                    <Text fontSize='16px'>{oneTimePayment}</Text>
                  </HStack>

                  {/* due now */}
                  <HStack w='100%' justifyContent='space-between'>
                    <Text fontSize='16px' fontWeight={700}>
                      Due Now:
                    </Text>
                    <Text fontSize='16px' fontWeight={700}>
                      {oneTimePayment}
                    </Text>
                  </HStack>

                  {/* spacer */}
                  <Divider borderColor='gray.400' />

                  {/* due monthly */}
                  <HStack w='100%' justifyContent='space-between'>
                    <Text fontSize='16px' fontWeight={700}>
                      Due Monthly:
                    </Text>
                    <Text fontSize='16px' fontWeight={700}>
                      $0.00
                    </Text>
                  </HStack>
                </Flex>
              </Card>

              {/* monthly card */}
              <Card
                border='1px solid #E1E6EF'
                rounded='10px'
                className='pt-10 pb-10 pl-5 pr-5 w-[50%] mobile:w-full'
                gap={10}
              >
                {/* cost section */}
                <VStack
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  spacing={5}
                >
                  {/* tags */}
                  <Tag
                    w='fit-content'
                    py='4px'
                    px='16px'
                    rounded='14px'
                    bg='#E1E6EF'
                    textColor='#191923'
                  >
                    MONTHLY PAYMENT
                  </Tag>

                  {/* cost price */}
                  <HStack alignItems='flex-end'>
                    <Text
                      lineHeight='42px'
                      textColor='#111827'
                      fontWeight={800}
                      fontSize='40px'
                    >
                      {monthlyAmount}
                    </Text>
                    <Text fontSize='24px' fontWeight={500} textColor='#191923'>
                      /mo
                    </Text>
                  </HStack>
                </VStack>

                {/* CTA section */}
                <Button
                  onClick={() => {
                    trackCustomEvent('planSelected', {
                      paymentType: 'monthly',
                      planId,
                    });
                    dispatch(savePlanPaymentMode(planPaymentMode.MONTHLY));
                    onNextClick();
                  }}
                  title='Purchase Plan'
                />

                {/* additional information section */}
                <Flex flexDir='column' gap={3} mt={3}>
                  {/* monthly payment */}
                  <HStack w='100%' justifyContent='space-between'>
                    <Text fontSize='16px'>First Monthly Payment</Text>
                    <Text fontSize='16px'>{monthlyAmount}</Text>
                  </HStack>

                  {/* due now */}
                  <HStack w='100%' justifyContent='space-between'>
                    <Text fontSize='16px' fontWeight={700}>
                      Due Now:
                    </Text>
                    <Text fontSize='16px' fontWeight={700}>
                      {monthlyAmount}
                    </Text>
                  </HStack>

                  {/* spacer */}
                  <Divider borderColor='gray.400' />

                  {/* due monthly */}
                  <HStack w='100%' justifyContent='space-between'>
                    <Text fontSize='16px' fontWeight={700}>
                      Due Monthly:
                    </Text>
                    <Text fontSize='16px' fontWeight={700}>
                      {monthlyAmount}
                    </Text>
                  </HStack>
                </Flex>
              </Card>
            </Flex>

            {/* plan card and info */}
            <Flex className='flex-col gap-6'>

              {/* plan heading and status */}
              <Flex className='flex-col gap-4 w-full pt-8'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>Your Plan</h2>
                  <StatusLabel status={singlePlan?.status} />
                </Flex>
                <Text className='mb-4'>
                  Your coverage plan will give you the flexibility to move any time
                  between your lease start and end date. For full details on your coverage plan please visit the <AnchorLinkTag className='underline' hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>lease flexibility coverage terms and conditions</AnchorLinkTag> on our website.
                </Text>
              </Flex>

              {/* plan info card */}
              <Flex className='flex-col gap-4 w-full'>
                <PlanInfoCard
                  streetAddress1={address?.streetAddress1}
                  streetAddress2={address?.streetAddress2}
                  city={address?.city}
                  state={address?.state}
                  country={address?.country}
                  postalCode={address?.postalCode}
                  name={userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`}
                  startDate={singlePlan?.lease?.startDate}
                  endDate={singlePlan?.lease?.endDate}
                  planId={singlePlan?.id}
                  inactive
                />
              </Flex>
            </Flex>

            {/* cancellation policy */}
            <Flex className='flex-col gap-4'>
              <h2 className='text-2xl font-bold'>
                Cancellation Policy
              </h2>
              <Text>
                If you move before the end of your lease term, you will be
                refunded the pro-rated amount for the unused months of your
                coverage plan.
              </Text>
            </Flex>

            {/* Frequently Asked Questions */}
            <Flex justifyContent='space-between' w='100%' gap={6}>
              {/* title */}
              <Text
                textColor='#191923'
                fontSize={{ base: '20px', lg: '24px' }}
                fontWeight={700}
                textAlign={{ base: 'left', lg: 'center' }}
              >
                Frequently Asked Questions
              </Text>

              {/* link */}
              <AnchorLinkTag
                hrefPath='https://www.getcios.com/faq/residents'
                className='bg-[#F2F6EF] w-10 h-10 rounded-[50%] inline-flex justify-center items-center'
              >
                <FaExternalLinkAlt className='text-xl' />
              </AnchorLinkTag>
            </Flex>

            {/* Talk to an expert */}
            <Flex flexDir='column' alignItems='flex-start' w='100%' gap={6}>
              {/* title */}
              <Text
                textColor='#191923'
                fontSize={{ base: '20px', lg: '24px' }}
                fontWeight={700}
                textAlign={{ base: 'left', lg: 'center' }}
              >
                Talk to an expert
              </Text>

              {/* email and phone */}
              <Flex flexDir='column' gap='12px' w='100%'>
                {/* email */}
                <HStack
                  justifyContent='space-between'
                  border='1.05px solid #E1E6EF'
                  p='10px'
                  rounded='10px'
                  bg='#F2F6EF'
                >
                  <Text textColor='#191923' fontWeight={700} fontSize='16px'>
                    Email us :
                  </Text>
                  <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                    members@releaserent.com
                  </Text>
                </HStack>

                {/* phone */}
                <HStack
                  justifyContent='space-between'
                  border='1.05px solid #E1E6EF'
                  p='10px'
                  rounded='10px'
                  bg='#F2F6EF'
                >
                  <Text textColor='#191923' fontWeight={700} fontSize='16px'>
                    Call us :
                  </Text>
                  <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                    {`(701) 515-9759 `}
                  </Text>
                </HStack>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
      {/* Buttons */}
      {/* <ApplyPlansButtons */}
      {/*   moveOn={true} */}
      {/*   disableBackBtn={isEmpty(singlePlan)} */}
      {/*   isValid={!isEmpty(singlePlan)} */}
      {/*   onNextClick={onNextClick} */}
      {/*   stepNumber={stepNumber} */}
      {/*   onBackClick={onBackClick} */}
      {/* /> */}
    </Flex>
  );
};

export default PlanStepTwo;
