import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import 'react-international-phone/style.css';
import { Provider } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import UserTrackerProvider from 'common/utils/user-tracker/provider';
import LoadingPageLayout from './layouts/loading-page';
import { store } from './redux/store';
import AppRouter from './routing/router';

// persistor
let persistor = persistStore(store);

// stripe Promise
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

// Chakra UI theme
const theme = extendTheme({
  styles: {
    global: {
      body: {
        overflow: 'auto !important',
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingPageLayout />} persistor={persistor}>
        <UserTrackerProvider>
          <ChakraProvider theme={theme}>
            <AppRouter />
          </ChakraProvider>
        </UserTrackerProvider>
      </PersistGate>

      {/* toast container */}
      <ToastContainer
        position='top-right'
        autoClose={3000}
        newestOnTop
        limit={1}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Zoom}
        pauseOnHover
        draggablePercent={40}
        theme='colored'
      />
    </Provider>
  );
}

export default App;
