import React, { useEffect } from 'react';
import { FaMoneyCheckDollar, FaTeamspeak, FaCircleInfo } from 'react-icons/fa6';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoMdInformationCircle } from 'react-icons/io';
import { IoIosSend, IoMdSettings } from 'react-icons/io';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import DashboardCard from '../../../components/shared/dashboard-card';
import DashboardMessage from 'components/shared/dashboard-message';
import DashboardMainLayout from '../../../layouts/dashboard-main';
import {
  useGetAllClaimsQuery,
  useGetAllPlansQuery,
  useGetDashboardMessagesQuery,
} from '../../../redux/api/ciosUsersApi/ciosUsersApi';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const DashboardHome = () => {
  const { trackPageLoad, trackCustomEvent, setFormData } = useUserTracker();

  // For some reason I can't use the tracker element, causes weird page loading issues
  useEffect(() => {
    trackPageLoad('dashboardHome');
    setFormData({});
  }, []);
  // HOOKS
  const { data: claimsData, isLoading: isGetAllClaimsLoading } =
    useGetAllClaimsQuery();
  const { data: plansData, isLoading: getAllPlansLoading } =
    useGetAllPlansQuery();
  const { data: dashboardMessages, isLoading: getDashboardMessagesLoading } =
    useGetDashboardMessagesQuery();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.500', 'gray.300');

  return (
    <DashboardMainLayout title='Home'>
      {dashboardMessages?.message && (

        // July 25 2024 - Replaced Taylor's rough box with a proper chakra-ui implementation for the activate card
        <DashboardMessage 
          Icon={RiErrorWarningFill}
          path={`/dashboard/apply-for-plans?step=5&status=pending&isSubmitted=true&planId=${dashboardMessages.planID}`}
          title='Activate Plan'
          description={dashboardMessages.message}
        />
      )}

      <div className='w-full grid grid-cols-4 gap-8 mobile:grid-cols-2 mobile:gap-5 mb-6'>
        {/* Plans Card */}
        <DashboardCard
          loading={getAllPlansLoading}
          title='Plans'
          count={plansData?.length ?? 0}
          path='/dashboard/plans'
          Icon={IoIosSend}
        />

        {/* Claims Card */}
        <DashboardCard
          loading={isGetAllClaimsLoading}
          title='Moves'
          count={claimsData?.length ?? 0}
          path='/dashboard/moves'
          Icon={RiErrorWarningFill}
        />

        {/* settings */}
        <DashboardCard
          title='Settings'
          count={0}
          path='/dashboard/settings'
          Icon={IoMdSettings}
        />
      </div>
      
      {/* Frequently Asked Questions */}
      <Flex justifyContent='space-between' w='100%' gap={6}>
        {/* title */}
        <Text
          textColor='#191923'
          fontSize={{ base: '20px', lg: '24px' }}
          fontWeight={700}
          textAlign={{ base: 'left', lg: 'center' }}
        >
          Frequently Asked Questions
        </Text>

        {/* link */}
        <AnchorLinkTag
          hrefPath='https://www.getcios.com/faq/residents'
          className='bg-[#F2F6EF] w-10 h-10 rounded-[50%] inline-flex justify-center items-center'
        >
          <FaExternalLinkAlt className='text-xl' />
        </AnchorLinkTag>
      </Flex>
      <Flex flexDir='column' alignItems='flex-start' w='100%' gap={6}>
        {/* title */}
        <Text
          textColor='#191923'
          fontSize={{ base: '20px', lg: '24px' }}
          fontWeight={700}
          textAlign={{ base: 'left', lg: 'center' }}
        >
          Talk to an expert
        </Text>

        {/* email and phone */}
        <Flex flexDir='column' gap='12px' w='100%'>
          {/* email */}
          <HStack
            justifyContent='space-between'
            border='1.05px solid #E1E6EF'
            p='10px'
            rounded='10px'
            bg='#F2F6EF'
          >
            <Text textColor='#191923' fontWeight={700} fontSize='16px'>
              Email us :
            </Text>
            <Text fontSize='16px' fontWeight={400} textColor='#191923'>
              members@releaserent.com
            </Text>
          </HStack>

          {/* phone */}
          <HStack
            justifyContent='space-between'
            border='1.05px solid #E1E6EF'
            p='10px'
            rounded='10px'
            bg='#F2F6EF'
          >
            <Text textColor='#191923' fontWeight={700} fontSize='16px'>
              Call us :
            </Text>
            <Text fontSize='16px' fontWeight={400} textColor='#191923'>
              {`(701) 515-9759 `}
            </Text>
          </HStack>
        </Flex>
      </Flex>
    </DashboardMainLayout>
  );
};

export default DashboardHome;
