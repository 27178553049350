import { FaCheck, FaRegUser } from 'react-icons/fa';

const PlanProgressCard = ({
  step,
  isBlurred,
  isCompleted,
  title,
  Icon,
  zIndex = '',
}) => {
  return (
    <>
      <div
        className={`${
          isBlurred ? 'opacity-[0.175]' : ''
        } w-full h-[105px] rounded-lg border-2 px-6 flex items-center py-3 border-black gap-4 mobile:hidden relative`}
      >
        {/* icon */}
        {isCompleted ? (
          <span className='inline-flex items-center justify-center p-4 border bg-[#191923] rounded-[50%] '>
            <FaCheck className='text-xl text-white' />
          </span>
        ) : (
          <span className='inline-flex items-center justify-center p-4 border border-[#191923] bg-[#F2F6EF] rounded-[50%] '>
            {Icon ? (
              <Icon className='text-xl' />
            ) : (
              <FaRegUser className='text-xl' />
            )}
          </span>
        )}

        {/* texts */}
        <div className='flex flex-col gap-1'>
          <p className='text-xs font-semibold text-[#191923]'>Step {step}</p>

          <p className='text-lg text-[#191923] font-bold'>{title}</p>
        </div>
      </div>

      {/* mobile: */}
      {isCompleted ? (
        <span
          className={`items-center justify-center h-[44.99px] w-[44.99px] border-[2.25px] bg-[#191923] rounded-[50%] hidden mobile:inline-flex ${zIndex} ${
            isBlurred ? 'opacity-[0.175]' : ''
          }`}
        >
          <FaCheck className='text-xl text-white' />
        </span>
      ) : (
        <span
          className={`items-center justify-center h-[44.99px] w-[44.99px] border-[2.25px] rounded-[50%] hidden mobile:inline-flex ${zIndex} ${
            isBlurred
              ? 'bg-[#F2F6EF] border-[#1919232a]'
              : 'bg-[#F2F6EF] border-[#191923]'
          }`}
        >
          {Icon ? (
            <Icon
              className={`text-xl ${
                isBlurred ? 'text-[#19192321]' : 'text-[#191923]'
              }`}
            />
          ) : (
            <FaRegUser
              className={`text-xl ${
                isBlurred ? 'text-[#19192321]' : 'text-[#191923]'
              }`}
            />
          )}
        </span>
      )}
    </>
  );
};

export default PlanProgressCard;
