import { BiRightArrowAlt } from 'react-icons/bi';
import Button from 'common/components/button';

const ApplyClaimsButtons = ({
  onBackClick,
  stepNumber,
  moveOn,
  handleSubmit,
  onNextClick,
  loading,
  // isValid,
  disabledNextBtn,
}) => {
  return (
    <div className='flex gap-4 items-center mobile:flex-col'>
      <Button
        disabled={stepNumber === 1}
        onClick={onBackClick}
        theme='secondary'
        title='Back'
      />
      <Button
        loading={loading}
        // disabled={loading || (stepNumber === 4 && !isValid)}
        // disabled={loading || !isValid || disabledNextBtn}
        disabled={loading || disabledNextBtn}
        // onClick={stepNumber === 4 ? handleSubmit : onNextClick}
        onClick={moveOn ? onNextClick : handleSubmit}
        Icon={stepNumber < 4 && BiRightArrowAlt}
        title={stepNumber > 3 ? 'Submit' : 'Next'}
      />
    </div>
  );
};

export default ApplyClaimsButtons;
