/* eslint-disable no-unused-vars */
import { isEmpty } from 'common/utils/isEmpty';

const DocumentPreview = ({ fileUrl, fileName }) => {
  if (isEmpty(fileUrl))
    return (
      <div className='rounded-lg border-[1.5px] border-gray-500 p-4 flex w-full justify-between items-center '>
        {/* file name */}
        <span className='text-black text-sm font-semibold'>
          Empty file: No file was found.
        </span>
      </div>
    );
  return (
    <div className='rounded-lg border-[1.5px] border-[#E1E6EF] p-4 flex w-full justify-between items-center '>
      {/* file name */}
      <span className='text-[#6A6C70] text-sm font-normal'>{fileName}</span>

      {/* open click */}
      <a className='' target='_blank' href={fileUrl} rel='noopener noreferrer'>
        <span className='rounded-[3px] border-[0.5px] border-[#E7E7E7] py-1 px-2 text-[#191923] text-sm font-medium cursor-pointer hover:bg-gray-200'>
          open
        </span>
      </a>
    </div>
  );
};

export default DocumentPreview;
