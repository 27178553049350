import { FaCircleCheck } from 'react-icons/fa6';
import { IoMdInformationCircle } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfirmAClaimMutation } from '../../../redux/api/ciosUsersApi/ciosUsersApi';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import Button from 'common/components/button';

const StartClaimApprove = ({ isApprovedClaim, setIsApprovedClaim }) => {
  // HOOKS
  const [confirmClaim, { isLoading: confirmClaimLoading }] =
    useConfirmAClaimMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deductibleParam = searchParams.get('deductible');
  const moveOutDateParam = searchParams.get('moveOutDate');
  const claimNameParam = searchParams.get('claimName');
  const claimIDParam = searchParams.get('claimID');

  // HANDLERS
  const handleGoToDashboard = () => {
    navigate(`/dashboard/moves/${claimIDParam}`);
  };

  if (isApprovedClaim) {
    return (
      <div className='w-full flex flex-col justify-between gap-8 h-full'>
        {/* icon and title */}
        <div className='w-full flex flex-col items-center gap-5 text-center'>
          {/* icon */}
          <FaCircleCheck className='text-[48px] text-[#34D399]' />

          {/* title */}
          <p className='text-4xl font-bold text-[#191923]'>
            Your Claim is Confirmed.
          </p>

          {/* description */}
          <p className='text-base font-normal text-[#191923] text-center w-2/3 mx-auto'>
            {`Your claim has been successfully confirmed. Your move-out date is
            set for ${formatDateToHumanDate(
              moveOutDateParam
            )}. You will receive full move-out instructions via
            email within 3-5 working days.`}
          </p>
        </div>

        {/* move out data and plan ID */}
        <div className='flex flex-col items-center w-full mx-auto gap-5'>
          {/* move out date */}
          <div className='inline-flex flex-col gap-1 text-center'>
            <span className='text-xs font-semibold text-[#777777]'>
              MOVE OUT DATE
            </span>
            <span className='text-black font-semibold text-base'>
              {formatDateToHumanDate(moveOutDateParam)}
            </span>
          </div>

          {/* horizontal line */}
          <span className='w-1/3 h-[1.242px] bg-[#E1E6EF]' />

          {/* plan ID */}
          <div className='inline-flex flex-col gap-1 text-center'>
            <span className='text-xs font-semibold text-[#777777]'>PLAN</span>
            <span className='text-black font-semibold text-base'>
              {claimNameParam}
            </span>
          </div>
        </div>

        {/* button */}
        <div className='w-full flex flex-col gap-5'>
          <Button
            onClick={handleGoToDashboard}
            title='Ok, Return to Home'
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className='w-full flex flex-col gap-10'>
        {/* icon and title */}
        <div className='w-full flex flex-col items-center gap-5 text-center'>
          <IoMdInformationCircle className='text-[#B097E2] text-[54px] mx-auto' />
          <p className='text-4xl font-bold text-[#191923]'>Confirm Claim</p>
        </div>

        {/* description */}
        <p className='text-base font-normal text-[#191923] text-center w-4/5 mx-auto'>
          By pressing the button below you agree to finalize your claim, pay the
          deductible amount below, and vacate your rental unit before the below
          date:
        </p>

        {/* move out data and deductible info */}
        <div className='grid grid-cols-2 w-2/3 mx-auto gap-5 mobile:grid-cols-1'>
          {/* move out date */}
          <div className='inline-flex flex-col gap-1 text-center'>
            <span className='text-xs font-semibold text-[#777777]'>
              MOVE OUT DATE
            </span>
            <span className='text-black font-semibold text-base'>
              {formatDateToHumanDate(moveOutDateParam)}
            </span>
          </div>

          {/* deductible */}
          <div className='inline-flex flex-col gap-1 text-center'>
            <span className='text-xs font-semibold text-[#777777]'>
              DEDUCTIBLE AMOUNT
            </span>
            <span className='text-black font-semibold text-base'>
              {deductibleParam}
            </span>
          </div>
        </div>

        {/* buttons */}
        <div className='w-full flex flex-col gap-5'>
          <Button
            loading={confirmClaimLoading}
            onClick={() => {
              confirmClaim(claimIDParam)
                .unwrap()
                .then(() => {
                  setIsApprovedClaim(true);
                  toast.info(`Claim confirmed successfully!`, {
                    toastId: 'confirmClaimSuccess1',
                  });
                })
                .catch((error) => {
                  console.error(error);
                  handleGoToDashboard();
                  toast.error(error?.message || `Couldn't confirm this claim`, {
                    toastId: 'confirmClaimError1',
                  });
                });
            }}
            title='I Agree, Finalize My Claim'
          />
          <Button
            onClick={handleGoToDashboard}
            title='I Do Not Agree, Do Not Finalize My Claim'
            theme='secondary'
          />
        </div>
      </div>
    );
  }
};

export default StartClaimApprove;
