/* eslint-disable no-unused-vars */
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { statusEnum } from '../../../utils/enums';
import {
  formatDateToHumanDate,
  formatDateToHumanTime,
} from 'common/utils/formDateToHumanTime';
import StatusLabel from '../../shared/status-label';

const PlansTable = ({ plans }) => {
  // DATA INTIAILIZATION
  const navigate = useNavigate();
  const excludedStatuses = ['cancelled', 'complete', 'active'];

  // HANDLERS
  const getNavURL = (status, id, step) => {
    let navigationUrl = '';
    if (
      (step < 5 && status === statusEnum.active) ||
      (step < 5 && status === statusEnum.complete) ||
      (step < 5 && status === statusEnum.rejected) ||
      (step < 5 && status === statusEnum.cancelled)
    ) {
      navigationUrl = '';
    } else if (+step === 5) {
      navigationUrl = `/dashboard/plans/${id}`;
    } else if (+step === 4) {
      navigationUrl = `/dashboard/apply-for-plans?step=${step}&status=${status}&isSubmitted=true&planId=${id}`;
    } else {
      navigationUrl = `/dashboard/apply-for-plans?step=${step}&status=${status}&isSubmitted=false&planId=${id}`;
    }
    return navigationUrl;
  };
  const isValidStatus = (status) => !excludedStatuses.includes(status);  

  return (
    <table className='flex flex-col w-full'>

      {/* body */}
      <tbody className='w-full rounded-xl border border-gray-200 divide-y'>
        {plans.map((plan, index) => (
          isValidStatus(plan.status) && 
          <tr
            key={index}
            onClick={() =>
              navigate(getNavURL(plan.status, plan.id, plan?.step))
            }
            className='grid grid-cols-[1fr_auto_160px] w-full hover:bg-gray-50 px-3 py-2 cursor-pointer mobile:grid-cols-[1fr_auto]'
          > 
            {/* address, start date, end date */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start '>
              <span className='uppercase tracking-wider text-lg'>
                {plan?.lease?.address?.streetAddress1}{plan?.lease?.address?.streetAddress2 && ', '}{plan?.lease?.address?.streetAddress2}
              </span>
              <span className='text-[0.9rem]'>
                {formatDateToHumanDate(plan?.lease?.startDate || '')} - {formatDateToHumanDate(plan?.lease?.endDate || '')}
              </span>
            </td>

            {/* desktop-only steps counter */}
            <td className='w-full p-3 text-[#191923] text-base font-medium flex items-center mobile:hidden'>
              <span>
                Step {plan?.step || 'empty'} / 5
              </span>
            </td>

            {/* status */}
            <td className='w-full p-3 flex items-center'>
              <div className=' inline-flex justify-between w-full items-center'>
                <StatusLabel status={plan.status} size='xs' />

                {plan?.status === statusEnum.cancelled ? null : (
                  <MdKeyboardArrowRight className='text-xl text-[#191923] mobile:hidden' />
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PlansTable;
