import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Divider, Flex, Text } from '@chakra-ui/react';
import FileUploadInput from 'components/shared/file-upload-input';
import WrapInputLabel from 'components/shared/wrap-input-label';
import ApplyPlansButtons from 'layouts/apply-plans-buttons';
import { useUploadRequestUrlMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useSelector } from 'react-redux';
import { selectPlansValues } from 'redux/features/plans/plansSlice';
import {
  useGetActivatePlanQuery,
  useActivatePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import { toast } from 'react-toastify';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { uploadFileToS3 } from 'utils/uploadS3';
import { extractErrorMsg } from 'utils/errors';
import DocumentPreview from 'components/shared/document-preview';

const PlanStepFive = ({ stepNumber, onBackClick, onNextClick, ...args }) => {
  const [leaseDoc, setLeaseDoc] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    url: '',
  });
  const {
    trackLink,
    trackPageLoad,
    trackCustomEvent,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();
  useEffect(() => {
    trackPageLoad('activatePlanLeaseAndVerification');
  }, []);
  const [activatePlan, { isLoading: isActivateLoading }] =
    useActivatePlanMutation();
  const [idDoc, setIDDoc] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    url: '',
  });
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const plansValue = useSelector(selectPlansValues);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchPlanId = searchParams.get('planId');
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetActivatePlanQuery(plansValue?.id || searchPlanId, {});

  console.log(singlePlan);
  let planId = plansValue?.id || singlePlan?.id || searchPlanId;

  // Check for previous lease doc
  useEffect(() => {
    if (singlePlan?.leaseDocUrl) {
      setLeaseDoc((old) => ({
        ...old,
        isLoading: false,
        isSuccess: true,
        isError: false,
        url: singlePlan?.leaseDocUrl,
      }));
    }

    if (singlePlan?.idUrl) {
      setIDDoc((old) => ({
        ...old,
        isLoading: false,
        isSuccess: true,
        isError: false,
        url: singlePlan?.idUrl,
      }));
    }
  }, [singlePlan?.leaseDocUrl, singlePlan?.idUrl]);

  return (
    <div className='flex flex-col w-full gap-7'>
      {/* verify identity */}
      <div className='flex flex-col gap-10 pb-8 items-center mobile:gap-2 mobile:pb-2'>
        {/* Lease Document is conditional on not being previous */}
        {!singlePlan?.leaseDocUrl && (
          <Flex flexDir='column' w='100%' gap='20px'>
            {/* title */}
            <Text fontSize='18px' fontWeight={700} textColor='#191923'>
              Lease Document
            </Text>

            <div className='w-full flex flex-col gap-2'>
              <WrapInputLabel isRequired={true}>
                <label className='text-[#191923] text-lg font-medium'>
                  Upload Your Current Lease Agreement
                </label>
              </WrapInputLabel>

              {/* file upload input */}
              <div className='w-full flex flex-col items-stretch'>
                <FileUploadInput
                  disableUpload={leaseDoc.isLoading}
                  isUploadError={leaseDoc.isError}
                  isUploadLoading={leaseDoc.isLoading}
                  isUploadSuccess={leaseDoc.isSuccess}
                  handlePDFClear={() => {
                    setLeaseDoc((old) => ({
                      ...old,
                      isLoading: false,
                      isSuccess: false,
                      isError: false,
                      url: '',
                    }));
                  }}
                  handlePDFUpload={async (file) => {
                    let uploadUrlReq;
                    let fileTrack = { fileName: file.name, size: file.size };
                    setLeaseDoc((old) => ({
                      ...old,
                      isLoading: true,
                      fileName: file.name,
                    }));

                    // Get the signing url for uploading to s3
                    try {
                      trackCustomEvent('fileUploadSelection', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                      });
                      trackCustomEvent('s3UrlUploadRequest', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                      });
                      uploadUrlReq = await uploadRequestUrl({
                        fileName: file.name,
                        contentType: file.type,
                      }).unwrap();
                    } catch (e) {
                      setLeaseDoc((old) => ({
                        ...old,
                        isLoading: false,
                        isError: true,
                        fileName: file.name,
                      }));
                      const errMsg = extractErrorMsg(
                        e,
                        'failed to upload lease document'
                      );

                      toast.error(errMsg);
                      trackCustomEvent('s3UrlUploadRequestError', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        error: errMsg,
                      });
                      return;
                    }

                    if (
                      !uploadUrlReq?.data?.uploadUrl ||
                      !uploadUrlReq?.data?.viewUrl
                    ) {
                      setLeaseDoc((old) => ({
                        ...old,
                        isLoading: false,
                        isError: true,
                        fileName: file.name,
                      }));
                      toast.error('failed to upload lease document');
                      trackCustomEvent('s3UrlUploadRequestError', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        error: 'Improper s3 url return value',
                      });
                    }

                    // Upload the file to s3
                    try {
                      trackCustomEvent('s3FileUploadRequest', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });
                      await uploadFileToS3({
                        s3Url: uploadUrlReq?.data?.uploadUrl,
                        file,
                      });

                      trackCustomEvent('s3FileUploadSuccess', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });

                      // Have to set the uploaded file url on the lease document
                      setLeaseDoc((old) => ({
                        ...old,
                        url: uploadUrlReq?.data?.viewUrl,
                        fileName: file.name,
                        isLoading: false,
                        isError: false,
                        isSuccess: true,
                      }));

                      toast.info(
                        uploadUrlReq?.status ||
                          'lease has been uploaded successfully'
                      );
                    } catch (e) {
                      setLeaseDoc((old) => ({
                        ...old,
                        fileName: file.name,
                        isLoading: false,
                        isError: true,
                        isSuccess: false,
                      }));
                      const errMsg = extractErrorMsg(
                        e,
                        'failed to upload lease document'
                      );
                      toast.error(errMsg);
                      trackCustomEvent('s3FileUploadError', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                        error: errMsg,
                      });
                      return;
                    }
                  }}
                />
              </div>

              {leaseDoc?.url && (
                <div className='w-full flex flex-col items-center gap-2 mt-4'>
                  <div className='w-full flex'>
                    <label className='text-[#191923] font-medium'>
                      View Uploaded Lease Docs
                    </label>
                  </div>
                  {/* uploaded file */}
                  <DocumentPreview
                    fileUrl={leaseDoc?.url}
                    fileName={leaseDoc.fileName}
                  />
                </div>
              )}
            </div>
          </Flex>
        )}
        {/* divider */}
        <Divider borderColor='gray.400' />

        {/* Identity Verification */}
        {!singlePlan?.idUrl && (
          <Flex flexDir='column' w='100%' gap='20px'>
            {/* title */}
            <Text fontSize='18px' fontWeight={700} textColor='#191923'>
              Identity Verification
            </Text>

            {/* sub-title */}
            <Text fontSize='16px' fontWeight={400} textColor='#191923'>
              {`To provide you with coverage we'll need to provide a means of  identification first. All you'll need is to upload an official government-issued ID (e.g. a drivers license, passport, or citizenship card).`}
            </Text>

            <div className='w-full flex flex-col gap-2'>
              <WrapInputLabel isRequired={true}>
                <label className='text-[#191923] text-lg font-medium'>
                  Upload Your ID
                </label>
              </WrapInputLabel>

              {/* file upload input */}
              <div className='w-full flex flex-col items-stretch'>
                <FileUploadInput
                  disableUpload={idDoc.isLoading}
                  isUploadError={idDoc.isError}
                  isUploadLoading={idDoc.isLoading}
                  isUploadSuccess={idDoc.isSuccess}
                  handlePDFClear={() => {
                    setIDDoc((old) => ({
                      ...old,
                      isLoading: false,
                      isSuccess: false,
                      isError: false,
                      url: '',
                    }));
                  }}
                  handlePDFUpload={async (file) => {
                    let uploadUrlReq;
                    setIDDoc((old) => ({ ...old, isLoading: true }));
                    let fileTrack = { fileName: file.name, size: file.size };

                    // Get the signing url for uploading to s3
                    try {
                      trackCustomEvent('fileUploadSelection', {
                        name: 'idUrl',
                        file: fileTrack,
                      });
                      trackCustomEvent('s3UrlUploadRequest', {
                        name: 'idUrl',
                        file: fileTrack,
                      });
                      uploadUrlReq = await uploadRequestUrl({
                        fileName: file.name,
                        contentType: file.type,
                      }).unwrap();
                    } catch (e) {
                      const errMsg =
                        e?.msg ||
                        e?.data?.msg ||
                        e?.message ||
                        'failed to upload lease document';
                      setIDDoc((old) => ({
                        ...old,
                        isLoading: false,
                        fileName: file.name,
                        isError: true,
                      }));
                      toast.error(errMsg);
                      trackCustomEvent('s3UrlUploadRequestError', {
                        name: 'idUrl',
                        file: fileTrack,
                        error: errMsg,
                      });
                      return;
                    }

                    if (
                      !uploadUrlReq?.data?.uploadUrl ||
                      !uploadUrlReq?.data?.viewUrl
                    ) {
                      setIDDoc((old) => ({
                        ...old,
                        isLoading: false,
                        fileName: file.name,
                        isError: true,
                      }));
                      toast.error('failed to upload id document');
                    }

                    // Upload the file to s3

                    try {
                      trackCustomEvent('s3FileUploadRequest', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });
                      await uploadFileToS3({
                        s3Url: uploadUrlReq?.data?.uploadUrl,
                        file,
                      });
                      trackCustomEvent('s3FileUploadSuccess', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });

                      // Have to set the uploaded file url on the id document
                      setIDDoc((old) => ({
                        ...old,
                        url: uploadUrlReq?.data?.viewUrl,
                        fileName: file.name,
                        isLoading: false,
                        isError: false,
                        isSuccess: true,
                      }));

                      toast.info(
                        uploadUrlReq?.status ||
                          'id has been uploaded successfully'
                      );
                    } catch (e) {
                      setIDDoc((old) => ({
                        ...old,
                        fileName: file.name,
                        isLoading: false,
                        isError: true,
                        isSuccess: false,
                      }));
                      const errMsg = extractErrorMsg(
                        e,
                        'failed to upload id document'
                      );
                      toast.error(errMsg);
                      trackCustomEvent('s3FileUploadError', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                        error: errMsg,
                      });
                      return;
                    }
                  }}
                />
              </div>

              {idDoc?.url && (
                <div className='w-full flex flex-col items-center gap-2 mt-4'>
                  <div className='w-full flex gap-2'>
                    <label className='text-[#191923] font-medium'>
                      View Uploaded ID Document
                    </label>
                  </div>
                  {/* uploaded file */}
                  <DocumentPreview
                    fileUrl={idDoc?.url}
                    fileName={idDoc.fileName}
                  />
                </div>
              )}
            </div>
          </Flex>
        )}
      </div>

      {/* Buttons */}
      <ApplyPlansButtons
        moveOn={true}
        isValid={
          !isActivateLoading &&
          !isGettingSinglePlan &&
          (leaseDoc.isSuccess || idDoc.isSuccess)
        }
        onNextClick={() => {
          const submitValues = {
            planId,
            leaseDocUrl: leaseDoc.url,
            idUrl: idDoc.url,
          };
          trackFormSubmission(submitValues);
          activatePlan(submitValues)
            .unwrap()
            .then((d) => {
              trackFormSubmissionSuccess(submitValues);

              if (leaseDoc.isSuccess && idDoc.isSuccess) {
                onNextClick();
              }
            })
            .catch((e) => {
              const errMsg = extractErrorMsg(e, 'Update Failed');
              toast.error(errMsg);
              trackFormSubmissionError(submitValues, errMsg);
            });
        }}
        stepNumber={stepNumber}
        onBackClick={() => {
          trackLink();
          onBackClick();
        }}
      />
    </div>
  );
};

export default PlanStepFive;
