import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { MAX_DOCUMENT_SIZE } from '../../../utils/enums';
import { isEmpty } from 'common/utils/isEmpty';

const FileUploadInput = ({
  disableUpload,
  isUploadSuccess,
  handlePDFUpload,
  isUploadLoading,
  isUploadError,
  handlePDFClear,
}) => {
  // STATES
  const [pickedPDF, setPickedPDF] = useState({
    path: '',
    name: '',
    size: 0,
    type: '',
    webkitRelativePath: '',
  });
  const [error, setError] = useState('');

  // HANDLERS
  const handleClearPickerImage = () => {
    handlePDFClear && handlePDFClear();
    setPickedPDF({
      path: '',
      name: '',
      size: 0,
      type: '',
      webkitRelativePath: '',
    });
  };

  // DATA INITIALIZATION
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf*': ['.pdf'],
      'image/*': ['.png', '.jpeg', '.jpg'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_DOCUMENT_SIZE,
    onDrop: (acceptedFiles) => {
      setError('');
      setPickedPDF(acceptedFiles[0]);
    },
    onDropRejected() {
      setError('This file was rejected');
    },
  });

  // SIDE EFFECTS
  useEffect(() => {
    if (!isEmpty(pickedPDF?.path)) {
      handlePDFUpload && handlePDFUpload(pickedPDF);
    }
  }, [pickedPDF]);

  return (
    <div className='relative'>
      <div
        {...(isEmpty(pickedPDF?.name) ? getRootProps() : undefined)}
        className={`flex items-center w-full border-[1.5px] rounded-lg bg-white h-11 ${
          isUploadSuccess && !isEmpty(pickedPDF?.name)
            ? 'px-[3px] border-green-600'
            : 'px-2 border-[#191923]2'
        } gap-x-3`}
      >
        {/* choose file button */}
        {/* check if file was uploaded successfully, if true, display a large green button that says 'success'*/}
        {isUploadSuccess && !isEmpty(pickedPDF?.name) ? (
          <button
            type='button'
            disabled={true}
            className='py-[4px] rounded-[3px] bg-green-700 inline-flex items-center justify-start px-2 text-white text-sm font-medium cursor-pointer w-full h-9 hover:bg-green-600 overflow-hidden'
          >
            {pickedPDF?.name || 'Uploaded'}
          </button>
        ) : isEmpty(pickedPDF?.name) ? (
          <button
            type='button'
            className='py-[4px] px-[10px] rounded-[3px] bg-[#E7E7E7] inline-flex items-center justify-center text-[#191923] text-sm font-medium cursor-pointer hover:bg-gray-300 disabled:opacity-50'
          >
            <input {...getInputProps()} />
            Choose file
          </button>
        ) : (
          // upload file button
          <button
            type='button'
            onClick={() => {
              handlePDFUpload(pickedPDF);
            }}
            disabled={
              (isUploadSuccess && !isEmpty(pickedPDF?.name)) ||
              isUploadLoading ||
              disableUpload
            }
            className={`py-[4px] rounded-[3px] ${
              isUploadError
                ? 'bg-red-500 hover:bg-red-300'
                : 'bg-green-600 hover:bg-green-300'
            } inline-flex items-center justify-center text-white text-sm font-medium cursor-pointer disabled:opacity-30 px-[10px]`}
          >
            {isUploadLoading
              ? 'Uploading...'
              : isUploadError
                ? 'Retry'
                : isUploadSuccess && !isEmpty(pickedPDF?.name)
                  ? 'Uploaded'
                  : 'Upload'}
          </button>
        )}

        {/* file description */}
        {isUploadSuccess ? null : !isEmpty(error) ? (
          <span className='font-normal text-sm text-red-600'>{error}</span>
        ) : !isEmpty(pickedPDF?.name) ? (
          <span className='font-normal text-sm text-[#191923]'>
            {pickedPDF?.name.length > 25
              ? pickedPDF?.name.slice(0, 25).concat('...')
              : pickedPDF?.name}
          </span>
        ) : (
          'No file chosen'
        )}
      </div>

      {/* remove button */}
      {isEmpty(pickedPDF?.name) ? null : (
        <span
          onClick={handleClearPickerImage}
          className='w-5 h-5 rounded-[50%] bg-red-700 inline-flex items-center justify-center absolute right-[10px] bottom-[50%] translate-y-[50%] hover:bg-red-500 transition-all cursor-pointer'
        >
          <MdClose className='text-white text-base' />
        </span>
      )}
    </div>
  );
};

export default FileUploadInput;
