/* eslint-disable no-unused-vars */
import PlanStepFive from 'components/plans/plan-step-five';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useGetSinglePlanQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { savePlansValues, setPlanStep } from 'redux/features/plans/plansSlice';
import { isEmpty } from 'common/utils/isEmpty';
import ActivePlanReg from '../../components/plans/active-plan-reg';
import PlanStepFour from '../../components/plans/plan-step-four';
import PlanStepOne from '../../components/plans/plan-step-one';
import PlanStepThree from '../../components/plans/plan-step-three';
import PlanStepTwo from '../../components/plans/plan-step-two';

const ApplyForPlanLayout = ({ stepNumber, onBackClick, setStepNumber }) => {
  // STATES
  const [status, setStatus] = useState('Pending Payment');

  // HOOKS
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsStatus = searchParams.get('status');
  const paramsPlanId = searchParams.get('planId');
  const dispatch = useDispatch();
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(paramsPlanId, {
      skip: isEmpty(paramsPlanId),
    });

  // HANDLERS
  const clearSearchParams = () => {
    searchParams.delete('status');
    searchParams.delete('planId');
    searchParams.delete('step');
    searchParams.delete('isSubmitted');
    setSearchParams(searchParams, { replace: true });
  };

  // DATA INITIALIZATION
  const stepComponents = [
    <PlanStepOne
      stepNumber={stepNumber}
      onBackClick={onBackClick}
      onNextClick={() => {
        setStepNumber(2);
        dispatch(setPlanStep(2));
      }}
      clearSearchParams={clearSearchParams}
      key={1}
    />,
    <PlanStepTwo
      isGettingPlan={isGettingSinglePlan}
      stepNumber={stepNumber}
      onBackClick={onBackClick}
      onNextClick={() => {
        clearSearchParams();
        setStepNumber(3);
        dispatch(setPlanStep(3));
      }}
      key={2}
    />,
    <PlanStepThree
      isGettingPlan={isGettingSinglePlan}
      onBackClick={onBackClick}
      onNextClick={() => {
        setStepNumber(4);
        dispatch(setPlanStep(4));
      }}
      key={3}
    />,
    <PlanStepFour
      isGettingPlan={isGettingSinglePlan}
      stepNumber={stepNumber}
      onBackClick={onBackClick}
      onNextClick={() => {
        setStepNumber(5);
        dispatch(setPlanStep(5));
      }}
      key={4}
    />,
    <PlanStepFive
      stepNumber={stepNumber}
      onBackClick={onBackClick}
      onNextClick={() => {
        setStepNumber(6);
        dispatch(setPlanStep(6));
      }}
      key={5}
    />,
    <ActivePlanReg key={6} />,
  ];

  // SIDE EFFECTS
  useEffect(() => {
    setStatus(paramsStatus || status);
  }, []);
  useEffect(() => {
    if (!isEmpty(singlePlan)) {
      dispatch(savePlansValues(singlePlan || {}));
    }
  }, [singlePlan]);

  return (
      // Cleaned up issues with mobile display and unnecessary sections
      // There's a colosal logic mess when it comes to what to display and when, leftover from Victor's poor understanding of the use case
      <section className='h-full w-full overflow-scroll no-scrollbar p-20 flex flex-col items-center border border-[#454546] rounded-[10px] mobile:border-none mobile:py-8 mobile:px-0 mobile:rounded-none'>
        
        {/* Internal container */}
        <div className='flex flex-col w-full gap-10'>
          
          {/* header */}
          <div className='flex items-center gap-8 pb-8 border-b border-gray-200'>
            
            {/* Step number */}
            {/* Steps 4-6 are shown visually as one step for users */}
            <div className='text-[#191923] border border-black rounded-full w-16 h-16 inline-flex items-center justify-center font-semibold text-4xl'>
              {stepNumber <= 4 ? stepNumber : 4}
            </div>

            {/* Title and subtitle */}
            <div className='flex flex-col w-full'>
              <h1 className='text-4xl text-[#191923] font-bold'>
                {stepNumber <= 3 ? 'Apply for a Coverage Plan' 
                : stepNumber === 4 || stepNumber === 5 ? 'Your Plan is Almost Ready'
                : "You're All Set"
                }
              </h1>
              <p className='text-[#191923] font-medium text-2xl'>
                {stepNumber === 1 ? 'About Your Lease' 
                : stepNumber === 2 ? 'Select Your Plan'
                : stepNumber === 3 ? 'Confirm Payment'
                : stepNumber === 4 || stepNumber === 5 ? 'Activate Your Plan'
                : 'Your Plan Is Ready To Go'
                }
              </p>
            </div>
          </div>

          {/* children content */}
          <div className='flex flex-col w-full gap-7'>
            {stepNumber > 6 ? (
              <div className='w-full h-full m-auto justify-center items-center flex'>
                <h2 className='text-[#191923] text-2xl font-semibold'>
                  Something went wrong. Please reload this page.
                </h2>
              </div>
            ) : (
              stepComponents[stepNumber - 1]
            )}
          </div>
        </div>
      </section>
  );
};

export default ApplyForPlanLayout;
