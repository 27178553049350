import { HiOutlineUser } from 'react-icons/hi';
import { MdOutlineMailOutline } from 'react-icons/md';
import Input from 'components/shared/input';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { Formik } from 'formik';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { enforcePhoneNumberPattern } from 'utils/enforcePhoneNumberPatter';
import { extractInitials } from 'common/utils/extractInitials';
import * as Yup from 'yup';
import WrapInputLabel from 'components/shared/wrap-input-label';
import FormError from 'components/shared/form-error';
import Button from 'common/components/button';
import { PhoneNumberInput } from 'common/components/phone-input';

// Phone number is not hooked up to anything

const NameEmailForm = ({ user }) => {
  const firstName = user?.firstName ? user?.firstName : user?.name.split(' ')[0]
  const lastName = user?.lastName ? user?.lastName : user?.name.split(' ')[1]
  const email = user?.email || 'hello@releaserent.com'
  const phone = user?.phone

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phone: formatPhoneNumberIntl(enforcePhoneNumberPattern(phone)) || '',
      }}
      validationSchema={Yup.object({
        phone: Yup.string()
          .required('Phone number is required')
          .test('is-valid-phone', 'Phone number is invalid', (value) => {
            return isValidPhoneNumber(value || '');
          }),
      })}
      onSubmit={() => {}}
    >
      {({ 
        isValid,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        dirty
       }) => {
        return (
          <form
            onSubmit={(e) => e.preventDefault()}
            className='w-full flex flex-col gap-5'
          >
            {/* note */}
            <div className='flex flex-col gap-2 text-[#191923]'>
              <h4 className='text-2xl font-semibold text-black'>Contact Information</h4>
              <h5 className='font-normal text-base text-[#637381]'>
                To update your contact information, please <AnchorLinkTag hrefPath='mailto:members@releaserent.com' className='text-[#1B6FEE]'>contact a ReLease representative</AnchorLinkTag>.
              </h5>
            </div>

            {/* form control container */}
            <div className='w-full flex items-center gap-6 mobile:flex-col mobile:gap-3'>
              <label className='flex flex-col gap-2 w-full text-lg font-medium'>
                First Name
                <Input
                  disabled={true}
                  value={firstName}
                  Icon={HiOutlineUser}
                  placeholderText='First Name'
                />
              </label>
              <label className='flex flex-col gap-2 w-full text-lg font-medium'>
                Last Name
                <Input
                  disabled={true}
                  value={lastName}
                  Icon={HiOutlineUser}
                  placeholderText='Last Name'
                />
              </label>
            </div>

            {/* form control container */}
            <div className='w-full flex flex-col gap-3'>
              <label className='flex flex-col gap-2 w-full text-lg font-medium'>
                Email Address
                <Input
                  disabled={true}
                  value={email}
                  Icon={MdOutlineMailOutline}
                  placeholderText='Email address'
                />
              </label>
            </div>

            {/* phone number */}
            <div className='w-full flex-col flex gap-2'>
              <WrapInputLabel isRequired={true}>
                <label className='text-lg font-medium text-[#191923]'>
                  Phone Number
                </label>
              </WrapInputLabel>
              <PhoneNumberInput 
                disabled={true}
                value={values.phone}
                onChange={(e) => {
                  setFieldValue('phone', e);
                }}
                onBlur={handleBlur}
              />
              <FormError name='phone' />
            </div>

            {/* button */}
            {/* <Button
              onClick={handleSubmit}
              disabled={!isValid || !dirty}
              title='Save changes'
            /> */}
          </form>
        );
      }}
    </Formik>
  );
};

export default NameEmailForm;
