import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import ClaimsInfoLayout from '../../../layouts/claims-info';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import Button from 'common/components/button';

const ClaimsDetailAction = ({
  claimName = '',
  claimID = '',
  deductible = '',
  approvedOn = '',
  expiresOn = '',
}) => {
  // HOOKS
  const navigateSearch = useNavigateSearch();

  return (
    <ClaimsInfoLayout title='Action Required !'>
      {/* texts */}
      <div className='flex flex-col w-full gap-7'>
        {/* title */}
        <p className='text-xl font-semibold text-black'>Feedback</p>

        {/* text contents */}
        <div className='grid w-full grid-cols-4 gap-y-10 gap-x-6 mobile:grid-cols-1 mobile:gap-y-5'>
          {/*  deductable amount */}
          <div className='flex flex-col gap-3'>
            <p className='text-xs text-[#191923] font-medium'>
              DEDUCTIBLE AMOUNT
            </p>

            <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
              {deductible}
            </p>
          </div>

          {/* approved on */}
          <div className='flex flex-col gap-3'>
            <p className='text-xs text-[#191923] font-medium'>APPROVED ON</p>

            <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
              {formatDateToHumanDate(approvedOn)}
            </p>
          </div>

          {/* Expires on */}
          <div className='flex flex-col gap-3'>
            <p className='text-xs text-[#191923] font-medium'>EXPIRES ON</p>

            <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
              {formatDateToHumanDate(expiresOn)}
            </p>
          </div>
        </div>

        {/* space */}
        <br />

        {/* confirm claim button */}
        <Button
          onClick={() => {
            navigateSearch('/dashboard/apply-for-claims', {
              claimName,
              claimID,
              step: 5,
              deductible,
              moveOutDate: expiresOn,
            });
          }}
          title='Confirm Claim'
        />
      </div>
    </ClaimsInfoLayout>
  );
};

export default ClaimsDetailAction;
