import React from 'react';
import Pagination from '../../components/shared/pagination';

const TablePaginateWrapper = ({
  children,
  setCurrentPage,
  totalCount,
  currentPage,
}) => {
  return (
    <div className='w-full min-h-[65dvh] flex flex-col justify-between gap-7'>
      {children}

      {/* pagination */}
      <Pagination
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalCount={totalCount}
      />
    </div>
  );
};

export default TablePaginateWrapper;
