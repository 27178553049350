import Button from 'common/components/button';
import StatusLabel from '../../shared/status-label';
import DocumentPreview from 'components/shared/document-preview';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

const ClaimSubmittedSuccess = ({ 
  onClick, 
  claimStatus,
  values,
}) => {
  return (
    <div className='w-full flex flex-col gap-6 h-full'>

      {/* button */}
      <div className='text-center flex flex-col gap-5 mb-8'>
        <Button onClick={onClick} title='Go To Dashboard' />
      </div>

      {/* content */}
      <div className='p-8 border-[1.5px] border-[#CED0CE] rounded-[10px] flex flex-col gap-8'>

        {/* general info for all claims */}
        <div className='flex gap-10 flex-wrap border-b pb-6'>
          {/* plan id */}
          <div className='flex flex-col gap-1'>
            <p className='text-[#777777] text-sm font-semibold'>PLAN ID</p>
            <p className='text-lg font-semibold text-black'>{values.planId}</p>
          </div>

          {/* move out date */}
          <div className='flex flex-col gap-1'>
            <p className='text-[#777777] text-sm font-semibold'>MOVE OUT DATE</p>
            <p className='text-lg font-semibold text-black'>
              {formatDateToHumanDate(values.moveOutDate)}
            </p>
          </div>

          {/* claim type */}
          <div className='flex flex-col gap-1'>
            <p className='text-[#777777] text-sm font-semibold'>CLAIM TYPE</p>
            <p className='text-lg font-semibold text-black'>{values.claimType}</p>
          </div>

          {/* claim status */}
          <div className='flex flex-col gap-1'>
            <p className='text-[#777777] text-sm font-semibold'>STATUS</p>
            <StatusLabel status={claimStatus} size='sm'/>
          </div>
        </div>

        {/* specific info for different claim types */}
        <div className='flex gap-10 flex-wrap border-b pb-6'>
          {values.claimType === 'newJob' ? (
          <>
            {/* company name */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                COMPANY NAME
              </p>
              <p className='text-lg font-semibold text-black'>
                {values.newJob.companyName}
              </p>
            </div>

            {/* date of offer */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                DATE OF OFFER
              </p>
              <p className='text-lg font-semibold text-black'>
                {formatDateToHumanDate(values.newJob.offerDate)}
              </p>
            </div>

            {/* proposed start date */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                PROPOSED START DATE
              </p>
              <p className='text-lg font-semibold text-black'>
                {formatDateToHumanDate(values.newJob.proposedStart)}
              </p>
            </div>

            {/* contact name */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                CONTACT NAME
              </p>
              <p className='text-lg font-semibold text-black'>
                {values.newJob.contactName}
              </p>
            </div>

            {/* contact email address */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                CONTACT EMAIL ADDRESS
              </p>
              <p className='text-lg font-semibold text-black'>
                {values.newJob.contactEmail}
              </p>
            </div>

            {/* contact phone */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                CONTACT PHONE
              </p>
              <p className='text-lg font-semibold text-black'>
                {values.newJob.contactPhone}
              </p>
            </div>

            {/* document submitted */}
            <div className='flex flex-col gap-1 w-full'>
              <p className='text-[#777777] text-sm font-semibold'>
                DOCUMENT SUBMITTED
              </p>
              <DocumentPreview
                shortenFileName={true}
                // Regex to remove the AWS url start
                fileName={values.newJob.offerPdfUrl.replace(/^.*listings\//, "")}
                fileUrl={values.newJob.offerPdfUrl}
              />
            </div>
          </>
          ) : values.claimType === 'homePurchase' ? (
          <>
            {/* date of offer */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                DATE OF OFFER
              </p>
              <p className='text-lg font-semibold text-black'>
                {formatDateToHumanDate(values.purchasedHome.offerDate)}
              </p>
            </div>

            {/* address */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>ADDRESS</p>
              <p className='text-lg font-semibold text-black'>
                {values.purchasedHome.homeAddress.streetAddress1 + ' ' +
                  values.purchasedHome.homeAddress.streetAddress2}
              </p>
            </div>

            {/* city */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>CITY</p>
              <p className='text-lg font-semibold text-black'>
                {values.purchasedHome.homeAddress.city}
              </p>
            </div>

            {/* state */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>STATE</p>
              <p className='text-lg font-semibold text-black'>
                {values.purchasedHome.homeAddress.state}
              </p>
            </div>

            {/* country */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>COUNTRY</p>
              <p className='text-lg font-semibold text-black'>
                {values.purchasedHome.homeAddress.country}
              </p>
            </div>

            {/* zip code */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>ZIP CODE</p>
              <p className='text-lg font-semibold text-black'>
                {values.purchasedHome.homeAddress.zipCode}
              </p>
            </div>

            {/* proposed start date */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                PROPOSED START DATE
              </p>
              <p className='text-lg font-semibold text-black'>
                {formatDateToHumanDate(values.purchasedHome.proposedStart)}
              </p>
            </div>

            {/* document submitted */}
            <div className='flex flex-col gap-1 w-full'>
              <p className='text-[#777777] text-sm font-semibold'>
                DOCUMENT SUBMITTED
              </p>
              <DocumentPreview
                shortenFileName={true}
                // Regex to remove the AWS url start
                fileName={values.purchasedHome.offerPdfUrl.replace(/^.*listings\//, "")}
                fileUrl={values.purchasedHome.offerPdfUrl || ''}
              />
            </div>
          </>
        ) : values.claimType === 'other' ? (
          <>
            {/* description */}
            <div className='flex flex-col gap-1'>
              <p className='text-[#777777] text-sm font-semibold'>
                DESCRIPTION
              </p>
              <p className='text-lg font-semibold text-black'>
                {values.otherClaim.description}
              </p>
            </div>

            {/* document submitted */}
            <div className='flex flex-col gap-1 w-full'>
              <p className='text-[#777777] text-sm font-semibold'>
                DOCUMENT SUBMITTED
              </p>
              <DocumentPreview
                shortenFileName={true}
                // Regex to remove the AWS url start
                fileName={values.otherClaim.supportingDocUrl.replace(/^.*listings\//, "")}
                fileUrl={values.otherClaim.supportingDocUrl || ''}
              />
            </div>
          </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ClaimSubmittedSuccess;
