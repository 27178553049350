import React from 'react';
import ClaimsInfoLayout from '../../../layouts/claims-info';

const ClaimsDetailAlert = ({ alertFeedback = '' }) => {
  return (
    <ClaimsInfoLayout title='Alert !'>
      {/* texts */}
      <div className='flex flex-col w-full gap-3'>
        {/* title */}
        <p className='text-xl font-semibold text-black'>Denial Feedback</p>

        {/* feedback description */}
        <p className='text-base font-normal text-black'>{alertFeedback}</p>
      </div>
    </ClaimsInfoLayout>
  );
};

export default ClaimsDetailAlert;
