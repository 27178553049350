import {
  Button,
  Card,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import StatusLabel from 'components/shared/status-label';
import DownloadPdfButton from 'components/plans/download-button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetSinglePlanQuery, useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  clearPlansDraft,
  selectPaymentMode,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { currency, planPaymentMode, statusEnum } from 'utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import PaymentSummary from 'components/plans/payment-summary';
import ClipboardButton from 'components/shared/clipboard-button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import PlanInfoCard from '../plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';

const PlanStepFour = ({
  stepNumber,
  onBackClick,
  onNextClick,
  isGettingPlan,
}) => {
  const { trackPageLoad, trackLink } = useUserTracker();
  useEffect(() => {
    trackPageLoad('planStepFour');
  }, []);

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentMode = useSelector(selectPaymentMode);
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  return (
    <Flex w='100%' flexDir='column' gap={10}>
      {isGettingSinglePlan || isGettingPlan ? (
        <Flex flexDir='column' gap='20px'>
          <Skeleton rounded='10px' h='10dvh' w='100%' />
          <Flex w='100%' gap='20px'>
            <Skeleton rounded='10px' h='40dvh' w='100%' />
            <Skeleton rounded='10px' h='40dvh' w='100%' />
          </Flex>
          <Skeleton rounded='10px' h='40dvh' w='100%' />
          <Skeleton rounded='10px' h='40dvh' w='100%' />
        </Flex>
      ) : isEmpty(singlePlan) ? (
        <Flex
          border='1px solid'
          borderColor='gray.300'
          rounded='12px'
          w='100%'
          alignItems='center'
          justifyContent='center'
          h='60dvh'
        >
          <Text fontWeight={600} fontSize='20px'>
            No plan found!
          </Text>
        </Flex>
      ) : (
        <>
          {/* container */}
          <Flex className='w-full flex-col gap-10'>

            {/* plan card and info */}
            <Flex className='flex-col gap-6'>

              {/* plan heading and status */}
              <Flex className='flex-col gap-4 w-full pt-8'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>Your Plan</h2>
                  <StatusLabel status={singlePlan?.status} />
                </Flex>
                <Text className='mb-4'>
                  Your coverage plan will give you the flexibility to move any time
                  between your lease start and end date. For full details on your coverage plan please visit the <AnchorLinkTag className='underline' hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>lease flexibility coverage terms and conditions</AnchorLinkTag> on our website.
                </Text>
              </Flex>

              {/* plan info card */}
              <Flex className='flex-col gap-4 w-full'>
                <PlanInfoCard
                  streetAddress1={singlePlan?.lease?.address?.streetAddress1}
                  streetAddress2={singlePlan?.lease?.address?.streetAddress2}
                  city={singlePlan?.lease?.address?.city}
                  state={singlePlan?.lease?.address?.state}
                  country={singlePlan?.lease?.address?.country}
                  postalCode={singlePlan?.lease?.postalCode}
                  name={userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`}
                  startDate={singlePlan?.lease?.startDate}
                  endDate={singlePlan?.lease?.endDate}
                  planId={singlePlan?.id}
                  inactive
                />
              </Flex>
            </Flex>

            {/* before activating plan */}
            <Flex className='flex-col gap-8 w-full p-8 rounded-xl border border-slate-300 border-solid' >

              {/* Before your plan is activated */}
              <Flex flexDir='column' w='100%' gap={1}>
                <h2 className='font-bold text-2xl text-[#191923]'>
                  {`Before Your Plan is Activated`}
                </h2>

                <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                  {`Please confirm a few details to activate your plan. It'll
                  only take you five minutes.`}
                </Text>
              </Flex>

              {/* ID VERIFICATION */}
              <Flex flexDir='column' w='100%' gap={1}>
                <Text fontSize='16px' fontWeight={700} textColor='#191923'>
                  {`VERIFY YOUR ID`}
                </Text>

                <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                  {`We require a government-issued ID before we activate your plan to help prevent fraud. Your privacy and identification are handled securely.`}
                </Text>
              </Flex>

              {/* Lease Verification */}
              <Flex flexDir='column' w='100%' gap={1}>
                <Text fontSize='16px' fontWeight={700} textColor='#191923'>
                  {`VERIFY YOUR LEASE AGREEMENT`}
                </Text>

                <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                  {`We require you upload a copy of your signed lease agreement to validate your lease details and to help prevent fraud.`}
                </Text>
              </Flex>
              
              <div className='w-full flex flex-col gap-4'>
                {/* Activate Plan */}
                <Button
                  onClick={() => {
                    trackLink('Activate Plan');
                    onNextClick();
                  }}
                  rounded='61px'
                  fontWeight={600}
                  linkId='activatePlan'
                  fontSize='16px'
                  size='lg'
                  border='1px solid'
                  color='#FBFEF9'
                  background='#191923'
                  borderColor='#191923'
                >
                  Activate Plan
                </Button>

                {/* Activate Plan Later */}
                <Button
                  onClick={() => {
                    trackLink('Activate Plan Later');
                    navigate('/dashboard/plans');
                    dispatch(clearPlansDraft());
                  }}
                  rounded='61px'
                  fontWeight={600}
                  fontSize='16px'
                  size='lg'
                  variant='ouline'
                  border='1px solid'
                  borderColor='gray.400'
                >
                  Activate Plan Later
                </Button>
              </div>
            </Flex>

            {/* payment summary */}
            <Flex className='flex-col gap-8 w-full ' >
              {/* title */}
              <h2 className='text-2xl font-bold'>
                Payment Summary
              </h2>

              {/* payment information */}
              <PaymentSummary plan={singlePlan} complete={true}/>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default PlanStepFour;
