/* eslint-disable no-unused-vars */
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Card, Divider, Flex, HStack, Skeleton, Text } from '@chakra-ui/react';
import Button from 'common/components/button';
import LoadingSpinner from 'components/shared/loading-spinner';
import FinishSetupIntent from 'components/stripe/finish-setup-intent';
import Payment from 'components/stripe/payment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetSinglePlanQuery,
  useRequestSetupIntentMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  selectPlansValues,
  selectPaymentMode,
} from 'redux/features/plans/plansSlice';
import { currency, planPaymentMode } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import PaymentSummary from 'components/plans/payment-summary';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { SetupCard } from 'components/stripe/setup-card';

const PlanStepThree = ({ onBackClick, onNextClick, isGettingPlan }) => {
  const { trackPageLoad, trackCustomEvent } = useUserTracker();

  useEffect(() => {
    trackPageLoad('planStepThree');
  }, []);

  // STATES
  const [clientSecret, setSecret] = useState();
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);

  // HOOKS
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });

  const [requestSetupIntent] = useRequestSetupIntentMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const setupIntent = searchParams.get('setup_intent');

  // SIDE EFFECTS
  useEffect(() => {
    requestSetupIntent()
      .unwrap()
      .then((response) => {
        setSecret(response?.data?.clientSecret);
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to setup intent!');
      });
  }, []);

  return (
    <Flex w='100%' flexDir='column' gap={10}>
      {/* container */}
      <Flex className='mobile:flex-col w-full gap-8'>

        {/* mobile payment summary */}
        <Accordion allowMultiple className='hidden mobile:flex w-full flex-col border border-slate-200 rounded-md overflow-hidden' >
          <AccordionItem className='border-none'>
            {/* title */}
            <h2 className='text-2xl font-bold'>
              <div className='capitalize p-4 flex gap-4 justify-between items-center'>
                <div>
                  {singlePlan?.payment.choice} Payment
                  <div className='text-base font-normal'>
                    {singlePlan?.payment.choice === 'monthly' 
                      ? 'Due Monthly'
                      : 'Due Today'
                    }
                  </div>
                </div>
                <div className='font-normal'>
                  ${singlePlan?.payment.choice === 'monthly' ? singlePlan?.payment.monthlyPayment : singlePlan?.payment.oneTimePayment} {singlePlan?.lease?.currency}
                </div>
              </div>
              <AccordionButton>
                <div className='w-full text-left text-sm font-semibold py-1 uppercase'>
                  Show Expanded Payment Summary
                </div>            
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel className='border-t py-8 flex flex-col gap-8'>
              {/* payment information */}
              <PaymentSummary plan={singlePlan} complete={true}/>

              {/* cancellation policy */}
              <Flex className='flex-col gap-2'>
                <h3 className='text-xl font-bold'>
                  Cancellation Policy
                </h3>
                
                <Text>
                  If you move before the end of your lease term, you will be
                  refunded the pro-rated amount for the unused months of your
                  coverage plan.
                </Text>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {/* payment link */}
        {/* back button only shown when payment intent isn't set up */}
        <div
          className='w-full gap-8 flex justify-center'
        >
          {clientSecret && (
          !isEmpty(setupIntent) ? (
              <FinishSetupIntent
                removeSetupIntent={() => {
                  searchParams.delete('setup_intent');
                  setSearchParams(searchParams, { replace: true });
                }}
                onNextClick={onNextClick}
                setPaymentSuccess={setPaymentSuccess}
                setupIntentID={setupIntent}
              />
            ) : (
              <Payment
                clientSecret={clientSecret}
                returnUrl={`${window.location.href}`}
                onClick={isEmpty(setupIntent) && onBackClick}
              />
            )
          )}
        </div>
        
        {/* payment summary */}
        <Flex className='flex-col gap-8 w-full mobile:hidden' >
          {/* title */}
          <h2 className='text-2xl font-bold'>
            Payment Summary
          </h2>

          {/* payment information */}
          <PaymentSummary plan={singlePlan} complete={true}/>

          {/* cancellation policy */}
          <Flex className='flex-col gap-2'>
            <h3 className='text-xl font-bold'>
              Cancellation Policy
            </h3>

            <Text>
              If you move before the end of your lease term, you will be
              refunded the pro-rated amount for the unused months of your
              coverage plan.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PlanStepThree;
